import React from 'react';
import { RadioButton } from '@hotelplan/components.common.buttons';
import { TabControls } from '@hotelplan/components.common.tabs';
import { T_Custom_TabsTravelTypesUnit } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import type { TravelTypeContentRenderer } from './fdr-travel-type-controls.types';

interface ITravelTypeRadioButton {
  travelType: T_Custom_TabsTravelTypesUnit;
  label: string;
  checked: boolean;
  onChange(value: T_Custom_TabsTravelTypesUnit): void;
  icon: string;
  testId?: string;
  renderTravelTypeContent?: TravelTypeContentRenderer | null;
}

export const FdrTravelTypeRadioButton: React.FC<ITravelTypeRadioButton> = ({
  travelType,
  label,
  checked,
  onChange,
  icon,
  testId,
  renderTravelTypeContent,
}) => {
  return (
    <RadioButton
      tabIndex={-1}
      hideLabel
      id={travelType}
      value={travelType}
      name={'travelTypes'}
      checked={checked}
      onChange={(value: T_Custom_TabsTravelTypesUnit) => onChange(value)}
      testId={testId}
      renderCustomControl={({ checked: nextChecked }) => {
        if (renderTravelTypeContent) {
          return renderTravelTypeContent({ checked: nextChecked });
        }
        return (
          <TabControls icon={icon} checked={nextChecked}>
            {label}
          </TabControls>
        );
      }}
    />
  );
};
