import type { DocumentNode } from 'graphql';
import { useQuery } from '@hotelplan/fdr.lib.apollo.query';
import { normalizeDocumentNode } from '@hotelplan/libs.graphql';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';

export type SearchControlDataMapper<TGetValuesQuery> = (
  data: TGetValuesQuery
) => IFdrSrlControlState | null;

export default function useFdrSearchControlValues<
  TGetValuesQuery extends {},
  TGetValuesVariables
>(
  query: DocumentNode,
  variables: TGetValuesVariables,
  dataMapper: SearchControlDataMapper<TGetValuesQuery>,
  skip?: boolean,
  onCompleted?: (data: TGetValuesQuery) => void
) {
  const { data, loading } = useQuery<TGetValuesQuery, TGetValuesVariables>(
    normalizeDocumentNode(query),
    {
      notifyOnNetworkStatusChange: true,
      variables,
      onCompleted,
      skip,
    }
  );

  return {
    loading,
    data: data ? dataMapper(data) : undefined,
  };
}
