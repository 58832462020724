import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrSearchControlsLightFragmentDoc } from 'fdr/schemas/fragment/search-controls/fdr-search-controls.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrFlightHomeSearchControlsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrFlightHomeSearchControlsQuery = {
  __typename?: 'Query';
  fdrFlightHomePage?: {
    __typename?: 'FdrFlightHomePage';
    searchControls: {
      __typename?: 'FdrSearchControlsResponse';
      controls: {
        __typename?: 'FdrSearchControls';
        rooms: number;
        travelType: Types.FdrProductTravelType;
        travellers: {
          __typename?: 'FdrSearchControlsTravellers';
          adults: number;
          childrenDobs?: Array<any> | null;
          roomDistribution?: Array<{
            __typename?: 'FdrSearchControlsTravellersDistribution';
            childrenDobs?: Array<any> | null;
            adults: number;
          }> | null;
        };
        travelPeriod: {
          __typename?: 'FdrSearchControlsTravelPeriod';
          defaultPeriod: Types.FdrTravelPeriodType;
          exactDepartureDate: any;
          exactReturnDate: any;
          flexibleDepartureDate: any;
          flexibleReturnDate: any;
          flexibleDurations: Array<number>;
        };
        travelDestinations?: {
          __typename?: 'FdrSearchControlsTravelDestinations';
          selectedSubGeo?: Array<string> | null;
          destinations?: Array<
            | {
                __typename?: 'FdrSearchControlsAirportDestination';
                airport: {
                  __typename?: 'FdrAirport';
                  iata: string;
                  name: string;
                  codes?: Array<string> | null;
                  isGroup: boolean;
                  id: string;
                };
              }
            | {
                __typename?: 'FdrSearchControlsGeoDestination';
                geo:
                  | {
                      __typename?: 'FdrDestination';
                      name: string;
                      id: string;
                      region?: {
                        __typename?: 'FdrRegion';
                        name: string;
                        id: string;
                        country: {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                          countryGroup?: {
                            __typename?: 'FdrCountryGroup';
                            name: string;
                            id: string;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          } | null;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        };
                      } | null;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                        countryGroup?: {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          id: string;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        } | null;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      };
                    }
                  | {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                      countryGroup?: {
                        __typename?: 'FdrCountryGroup';
                        name: string;
                        id: string;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      } | null;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    }
                  | { __typename?: 'FdrContinent'; name: string; id: string }
                  | {
                      __typename?: 'FdrCountryGroup';
                      name: string;
                      id: string;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    }
                  | {
                      __typename?: 'FdrRegion';
                      name: string;
                      id: string;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                        countryGroup?: {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          id: string;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        } | null;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      };
                    }
                  | {
                      __typename?: 'FdrResort';
                      name: string;
                      id: string;
                      destination: {
                        __typename?: 'FdrDestination';
                        name: string;
                        id: string;
                        region?: {
                          __typename?: 'FdrRegion';
                          name: string;
                          id: string;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                            countryGroup?: {
                              __typename?: 'FdrCountryGroup';
                              name: string;
                              id: string;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            } | null;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          };
                        } | null;
                        country: {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                          countryGroup?: {
                            __typename?: 'FdrCountryGroup';
                            name: string;
                            id: string;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          } | null;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        };
                      };
                    };
              }
            | {
                __typename?: 'FdrSearchControlsProductDestination';
                product:
                  | { __typename?: 'FdrCruise'; name: string; id: string }
                  | {
                      __typename?: 'FdrHotel';
                      name: string;
                      mythosCode?: string | null;
                      id: string;
                      resort: {
                        __typename?: 'FdrResort';
                        name: string;
                        id: string;
                        destination: {
                          __typename?: 'FdrDestination';
                          name: string;
                          id: string;
                          region?: {
                            __typename?: 'FdrRegion';
                            name: string;
                            id: string;
                            country: {
                              __typename?: 'FdrCountry';
                              name: string;
                              id: string;
                              countryGroup?: {
                                __typename?: 'FdrCountryGroup';
                                name: string;
                                id: string;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              } | null;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            };
                          } | null;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                            countryGroup?: {
                              __typename?: 'FdrCountryGroup';
                              name: string;
                              id: string;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            } | null;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          };
                        };
                      };
                    }
                  | { __typename?: 'FdrRoundTrip'; name: string; id: string }
                  | {
                      __typename?: 'FdrAdventureTravel';
                      name: string;
                      id: string;
                    };
              }
            | {
                __typename?: 'FdrSearchControlsThemeDestination';
                theme: { __typename?: 'FdrTheme'; name: string; id: string };
              }
          > | null;
          radiusSearch?: {
            __typename?: 'FdrSearchControlsRadiusSearch';
            label: string;
            radius: number;
            center: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            };
          } | null;
        } | null;
      };
    };
  } | null;
};

export const FdrFlightHomeSearchControlsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrFlightHomeSearchControls' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrFlightHomePage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'controls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrSearchControlsLight',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchControlsLightFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrFlightHomeSearchControlsQuery__
 *
 * To run a query within a React component, call `useFdrFlightHomeSearchControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrFlightHomeSearchControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrFlightHomeSearchControlsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrFlightHomeSearchControlsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrFlightHomeSearchControlsQuery,
    FdrFlightHomeSearchControlsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrFlightHomeSearchControlsQuery,
    FdrFlightHomeSearchControlsQueryVariables
  >(FdrFlightHomeSearchControlsDocument, options);
}
export function useFdrFlightHomeSearchControlsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrFlightHomeSearchControlsQuery,
    FdrFlightHomeSearchControlsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrFlightHomeSearchControlsQuery,
    FdrFlightHomeSearchControlsQueryVariables
  >(FdrFlightHomeSearchControlsDocument, options);
}
export type FdrFlightHomeSearchControlsQueryHookResult = ReturnType<
  typeof useFdrFlightHomeSearchControlsQuery
>;
export type FdrFlightHomeSearchControlsLazyQueryHookResult = ReturnType<
  typeof useFdrFlightHomeSearchControlsLazyQuery
>;
export type FdrFlightHomeSearchControlsQueryResult = Apollo.QueryResult<
  FdrFlightHomeSearchControlsQuery,
  FdrFlightHomeSearchControlsQueryVariables
>;
