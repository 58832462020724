import { BsButton } from '@hotelplan/core.basis.bs-button';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const SubmitButton = styled(BsButton)(
  sx2CssThemeFn({
    width: ['100%', 'auto'],
    px: [null, '24px'],
    minWidth: [null, '112px'],
    flexShrink: [null, '0'],
    alignSelf: 'flex-end',
  })
);

export const CancelButton = styled(BsButton)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.BUTTON,
    mt: [2, 0],
    mx: ['auto', 0],
    pb: 0,
  })
);

export const SearchControlInternalContainer = styled.div<{ sticky: boolean }>(
  ({ sticky, theme: { colors } }) =>
    sx2CssThemeFn({
      display: 'flex',
      px: 4,
      pt: 3,
      pb: [4, 3],
      borderBottom: ['1px solid', 'none'],
      borderColor: 'lightGreyFirst',
      flexDirection: ['column', 'row'],
      gap: [2, '10px'],
      '.travelDates': {
        width: sticky && '310px',
      },
      '.travelRooms': {
        width: sticky && '200px',
      },
      '.search-control-field-travel-types': {
        flex: [null, '1 1 auto'],
        '&:first-child': {
          width: [null, '230px'],
        },
      },
      '.search-control-field': {
        flex: [null, '1 1 auto'],
        '&:first-child': {
          maxWidth: [null, '330px'],
        },
        '.open': {
          input: {
            boxShadow: `inset 0 -3px 0 0 ${colors.primary}`,
          },
        },
      },
      '.travelDates.search-control-field': {
        '&:first-child': {
          minWidth: [null, '330px'],
        },
      },
      '.travel-rooms-field': {
        mb: 0,
        maxWidth: [null, '297px'],
      },
    })
);
