export const allowedTravelDestinationItemTypes = [
  `FdrContinent`,
  `FdrCountryGroup`,
  `FdrCountry`,
  `FdrRegion`,
  `FdrDestination`,
  `FdrResort`,
  `FdrAirport`,
  // `FdrAirportGroup`, //@to-check: is it needed?
  `FdrHotel`,
];
