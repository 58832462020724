import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrTravelPeriodFragmentDoc } from 'fdr/schemas/fragment/travel-dates/fdr-travel-period.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrPdpControlsTravelPeriodQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
  travelType?: Types.InputMaybe<Types.FdrProductTravelType>;
}>;

export type FdrPdpControlsTravelPeriodQuery = {
  __typename?: 'Query';
  fdrProduct?:
    | {
        __typename?: 'FdrCruise';
        id: string;
        searchControls: {
          __typename?: 'FdrSearchControlsResponse';
          controls: {
            __typename?: 'FdrSearchControls';
            travelPeriod: {
              __typename?: 'FdrSearchControlsTravelPeriod';
              defaultPeriod: Types.FdrTravelPeriodType;
              exactDepartureDate: any;
              exactReturnDate: any;
              flexibleDepartureDate: any;
              flexibleReturnDate: any;
              flexibleDurations: Array<number>;
            };
          };
        };
      }
    | {
        __typename?: 'FdrHotel';
        id: string;
        searchControls: {
          __typename?: 'FdrSearchControlsResponse';
          controls: {
            __typename?: 'FdrSearchControls';
            travelPeriod: {
              __typename?: 'FdrSearchControlsTravelPeriod';
              defaultPeriod: Types.FdrTravelPeriodType;
              exactDepartureDate: any;
              exactReturnDate: any;
              flexibleDepartureDate: any;
              flexibleReturnDate: any;
              flexibleDurations: Array<number>;
            };
          };
        };
      }
    | {
        __typename?: 'FdrRoundTrip';
        id: string;
        searchControls: {
          __typename?: 'FdrSearchControlsResponse';
          controls: {
            __typename?: 'FdrSearchControls';
            travelPeriod: {
              __typename?: 'FdrSearchControlsTravelPeriod';
              defaultPeriod: Types.FdrTravelPeriodType;
              exactDepartureDate: any;
              exactReturnDate: any;
              flexibleDepartureDate: any;
              flexibleReturnDate: any;
              flexibleDurations: Array<number>;
            };
          };
        };
      }
    | { __typename?: 'FdrAdventureTravel'; id: string }
    | null;
};

export type FdrControlsTravelPeriodResponseFragment = {
  __typename?: 'FdrSearchControlsResponse';
  controls: {
    __typename?: 'FdrSearchControls';
    travelPeriod: {
      __typename?: 'FdrSearchControlsTravelPeriod';
      defaultPeriod: Types.FdrTravelPeriodType;
      exactDepartureDate: any;
      exactReturnDate: any;
      flexibleDepartureDate: any;
      flexibleReturnDate: any;
      flexibleDurations: Array<number>;
    };
  };
};

export type FdrControlsTravelPeriodFragment = {
  __typename?: 'FdrSearchControls';
  travelPeriod: {
    __typename?: 'FdrSearchControlsTravelPeriod';
    defaultPeriod: Types.FdrTravelPeriodType;
    exactDepartureDate: any;
    exactReturnDate: any;
    flexibleDepartureDate: any;
    flexibleReturnDate: any;
    flexibleDurations: Array<number>;
  };
};

export const FdrControlsTravelPeriodFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrControlsTravelPeriod' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControls' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travelPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrTravelPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrTravelPeriodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrControlsTravelPeriodResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrControlsTravelPeriodResponse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'controls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrControlsTravelPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrControlsTravelPeriodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrPdpControlsTravelPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrPdpControlsTravelPeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travelType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FdrProductTravelType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publicId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrHotel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchControls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'searchQuery' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchQuery',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'travelType' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'travelType' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrControlsTravelPeriodResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRoundTrip' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchControls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'searchQuery' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchQuery',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'travelType' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'travelType' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrControlsTravelPeriodResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCruise' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchControls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'searchQuery' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchQuery',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'travelType' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'travelType' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrControlsTravelPeriodResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrControlsTravelPeriodResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrPdpControlsTravelPeriodQuery__
 *
 * To run a query within a React component, call `useFdrPdpControlsTravelPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrPdpControlsTravelPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrPdpControlsTravelPeriodQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      travelType: // value for 'travelType'
 *   },
 * });
 */
export function useFdrPdpControlsTravelPeriodQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrPdpControlsTravelPeriodQuery,
    FdrPdpControlsTravelPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrPdpControlsTravelPeriodQuery,
    FdrPdpControlsTravelPeriodQueryVariables
  >(FdrPdpControlsTravelPeriodDocument, options);
}
export function useFdrPdpControlsTravelPeriodLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrPdpControlsTravelPeriodQuery,
    FdrPdpControlsTravelPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrPdpControlsTravelPeriodQuery,
    FdrPdpControlsTravelPeriodQueryVariables
  >(FdrPdpControlsTravelPeriodDocument, options);
}
export type FdrPdpControlsTravelPeriodQueryHookResult = ReturnType<
  typeof useFdrPdpControlsTravelPeriodQuery
>;
export type FdrPdpControlsTravelPeriodLazyQueryHookResult = ReturnType<
  typeof useFdrPdpControlsTravelPeriodLazyQuery
>;
export type FdrPdpControlsTravelPeriodQueryResult = Apollo.QueryResult<
  FdrPdpControlsTravelPeriodQuery,
  FdrPdpControlsTravelPeriodQueryVariables
>;
