import React, { ReactElement } from 'react';
import { withLazyHydrate } from '@hotelplan/components.common.lazy-render';
import { FdrAutocompleteDataSource } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-data-source';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { flattenFdrAutocomplete } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-response-to-state.mapper';
import { FdrAutocompleteItemFragment } from 'fdr/schemas/query/search/autocomplete/fdr-text-search-autocomplete.generated';
import { trackDestinationsSearchResult } from './fdr-destination-field.tracking';
import {
  IFdrDestinationData,
  IFdrGenericTravelDestinationProps,
} from './fdr-destination-field.types';
import { FdrTravelDestinationField } from './field/fdr-travel-destination-field';

const FdrGenericDestinationField = <
  TData extends object,
  TVariables extends { text: string }
>(
  props: React.PropsWithChildren<
    IFdrGenericTravelDestinationProps<TData, TVariables>
  >
): ReactElement => {
  const {
    queryDocument,
    disabled,
    dataMapper,
    variablesMapper,
    suggestionMapper,
    config,
    limit,
    customLabel,
    caption,
    onSelect,
    renderSuggestion,
    countDataSource,
    topDestinationsShown,
  } = props;

  return (
    <FdrTravelDestinationField
      disabled={disabled}
      limit={limit}
      config={config}
      customLabel={customLabel}
      caption={caption}
      onSelect={onSelect}
      renderSuggestion={renderSuggestion}
      topDestinationsShown={topDestinationsShown}
    >
      <FdrAutocompleteDataSource<
        TFdrFlatDestination,
        TData,
        TVariables,
        FdrAutocompleteItemFragment
      >
        queryDocument={queryDocument}
        dataMapper={data => {
          return data
            ? {
                items: (dataMapper(data) as IFdrDestinationData).destinations,
                pagination: (dataMapper(data) as IFdrDestinationData).page,
              }
            : undefined;
        }}
        variablesMapper={variables => {
          return variablesMapper({
            selected: variables.selected
              .filter(item => Boolean(item.id))
              .map(({ id }) => id),
            text: variables.query,
            page: variables.page,
          });
        }}
        suggestionMapper={suggestionMapper ?? flattenFdrAutocomplete}
        onCompleted={(completedData, vars) => {
          const text = vars?.text || '';
          trackDestinationsSearchResult(dataMapper(completedData), text);
        }}
      />
      {countDataSource}
      {props.children}
    </FdrTravelDestinationField>
  );
};

export default withLazyHydrate(FdrGenericDestinationField, {
  whenIdle: true,
}) as typeof FdrGenericDestinationField;
