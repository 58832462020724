import { useTranslation } from 'next-i18next';
import React from 'react';
import CustomLabel from 'components/domain/CustomLabel';
import { FdrSearchTravelDates } from 'fdr/components/local/fdr-search-travel-dates';

export function FdrMixedSearchDropdown({
  disabled,
  className = '',
}: {
  disabled?: boolean;
  className?: string;
}) {
  const [t] = useTranslation('common');

  return (
    <FdrSearchTravelDates
      disabled={disabled}
      customLabel={
        <CustomLabel htmlFor="travelDates" className={className}>
          {t('search_form_label.travel_dates')}
        </CustomLabel>
      }
    />
  );
}
