import React from 'react';
import styled from 'styled-components';
import { FdrSearchControlSkeleton } from 'fdr/components/candidate/fdr-search-control/skeleton';

const SearchControlSkeletonWrapper = styled(FdrSearchControlSkeleton)(
  ({ theme }) => theme.forms.bannerForm || {}
);

interface IBannerSearchFormSkeletonProps {
  className?: string;
}

const BannerSearchFormSkeleton: React.FC<IBannerSearchFormSkeletonProps> = ({
  className,
}) => {
  return (
    <div className={className}>
      <SearchControlSkeletonWrapper
        className={'search-control-skeleton'}
        height={60}
      />
    </div>
  );
};

export default BannerSearchFormSkeleton;
