import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';

const DESTINATIONS_SEPARATOR = ',';

export function useFdrTravelDestinationAutocompleteHotelOnlyDestinations(): string[] {
  const { getVariant } = useFeatureToggle();
  const variant = getVariant(FeatureList.AUTOSUGGEST_HOTEL_ONLY_DESTINATIONS);

  if (!variant?.enabled || typeof variant?.payload?.type !== 'string') {
    return [];
  }

  return (variant.payload.value as string)
    .split(DESTINATIONS_SEPARATOR)
    .map(item => {
      return item.trim();
    });
}
