import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrControlsTravelPeriodResponseFragmentDoc } from 'fdr/schemas/query/pdp/search-controls/fdr-pdp-controls-travel-period.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrControlsTravelPeriodQueryVariables = Types.Exact<{
  travelType?: Types.InputMaybe<Types.FdrProductTravelType>;
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type FdrControlsTravelPeriodQuery = {
  __typename?: 'Query';
  fdrProductSearchResultListPage?: {
    __typename?: 'FdrProductSearchResultListPage';
    forceSrlSingleView: {
      __typename?: 'FdrForceSrlSingleView';
      value: boolean;
    };
    searchControls: {
      __typename?: 'FdrSearchControlsResponse';
      controls: {
        __typename?: 'FdrSearchControls';
        travelPeriod: {
          __typename?: 'FdrSearchControlsTravelPeriod';
          defaultPeriod: Types.FdrTravelPeriodType;
          exactDepartureDate: any;
          exactReturnDate: any;
          flexibleDepartureDate: any;
          flexibleReturnDate: any;
          flexibleDurations: Array<number>;
        };
      };
    };
  } | null;
};

export const FdrControlsTravelPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrControlsTravelPeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travelType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FdrProductTravelType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrProductSearchResultListPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forceSrlSingleView' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchQuery' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchQuery' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControls' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'travelType' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'travelType' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchQuery' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchQuery' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrControlsTravelPeriodResponse',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrControlsTravelPeriodResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrControlsTravelPeriodQuery__
 *
 * To run a query within a React component, call `useFdrControlsTravelPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrControlsTravelPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrControlsTravelPeriodQuery({
 *   variables: {
 *      travelType: // value for 'travelType'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useFdrControlsTravelPeriodQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrControlsTravelPeriodQuery,
    FdrControlsTravelPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrControlsTravelPeriodQuery,
    FdrControlsTravelPeriodQueryVariables
  >(FdrControlsTravelPeriodDocument, options);
}
export function useFdrControlsTravelPeriodLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrControlsTravelPeriodQuery,
    FdrControlsTravelPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrControlsTravelPeriodQuery,
    FdrControlsTravelPeriodQueryVariables
  >(FdrControlsTravelPeriodDocument, options);
}
export type FdrControlsTravelPeriodQueryHookResult = ReturnType<
  typeof useFdrControlsTravelPeriodQuery
>;
export type FdrControlsTravelPeriodLazyQueryHookResult = ReturnType<
  typeof useFdrControlsTravelPeriodLazyQuery
>;
export type FdrControlsTravelPeriodQueryResult = Apollo.QueryResult<
  FdrControlsTravelPeriodQuery,
  FdrControlsTravelPeriodQueryVariables
>;
