import { useGSSContext } from '@hotelplan/fdr.lib.search.with-global-state';
import { useSearchState } from '@hotelplan/fdr.lib.search.with-state';
import { FdrSorting } from '@hotelplan/supergraph-api';
import { fdrSrlFormToCriteriaInput } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-form-to-criteria-input.mapper';
import {
  IFdrProductSrlControlState,
  IFdrSRLState,
  TFdrProductSrlControlStateWithType,
} from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { IFdrFlightSrlControlState } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import { useFdrGetProductLink } from 'fdr/components/local/fdr-product-link/use-fdr-get-product-link';
import { useFdrGetSearchLink } from 'fdr/components/local/fdr-search-link/use-fdr-get-search-link';
import { useFdrNotOrlModal } from 'fdr/components/local/fdr-search-link/use-fdr-not-orl-modal';

export function useFdrOnSearchApply(
  initialFormValues?: IFdrSrlControlState | null
) {
  const { setGSS } =
    useGSSContext<IFdrProductSrlControlState, IFdrFlightSrlControlState>();
  const { state } = useSearchState<IFdrSRLState>();

  const { setProduct } = useFdrNotOrlModal();

  const [getSearchLink] = useFdrGetSearchLink();
  const [getProductLink] = useFdrGetProductLink();

  return (formState: TFdrProductSrlControlStateWithType) => {
    setGSS(initialFormValues || {}, formState);

    const productSorting = state?.productSorting as unknown as FdrSorting;

    const searchCriteria = {
      ...fdrSrlFormToCriteriaInput(
        formState,
        state?.filters,
        state?.subGeoFilters
      ),
      ...(productSorting ? { sorting: productSorting } : {}),
    };

    if (
      formState?.legacy_travelDestination?.ids?.productPublicIds?.length ===
        1 &&
      formState?.legacy_travelDestination?.ids?.geoPublicIds?.length === 0 &&
      formState?.legacy_travelDestination?.ids?.themePublicIds?.length === 0
    ) {
      getProductLink({
        variables: {
          offersInput: { searchCriteria },
          productInput: {
            publicId:
              formState?.legacy_travelDestination?.ids?.productPublicIds[0],
          },
        },
      });
    } else {
      getSearchLink({
        variables: { input: { searchCriteria } },
      });
    }

    setProduct(
      (formState.legacy_travelDestination?.destinations || [])
        .map(x => x.name)
        .join(', ')
    );
  };
}
