import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrProductLinkQueryVariables = Types.Exact<{
  productInput: Types.FdrProductInput;
  offersInput?: Types.InputMaybe<Types.FdrProductOffersWebMetaInput>;
}>;

export type FdrProductLinkQuery = {
  __typename?: 'Query';
  fdrProduct?:
    | {
        __typename?: 'FdrCruise';
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrHotel';
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrRoundTrip';
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrAdventureTravel';
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | null;
};

export const FdrProductLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrProductLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrProductInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offersInput' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FdrProductOffersWebMetaInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offersWebMeta' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'offersInput' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrLink' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrProductLinkQuery__
 *
 * To run a query within a React component, call `useFdrProductLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrProductLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrProductLinkQuery({
 *   variables: {
 *      productInput: // value for 'productInput'
 *      offersInput: // value for 'offersInput'
 *   },
 * });
 */
export function useFdrProductLinkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrProductLinkQuery,
    FdrProductLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrProductLinkQuery,
    FdrProductLinkQueryVariables
  >(FdrProductLinkDocument, options);
}
export function useFdrProductLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrProductLinkQuery,
    FdrProductLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrProductLinkQuery,
    FdrProductLinkQueryVariables
  >(FdrProductLinkDocument, options);
}
export type FdrProductLinkQueryHookResult = ReturnType<
  typeof useFdrProductLinkQuery
>;
export type FdrProductLinkLazyQueryHookResult = ReturnType<
  typeof useFdrProductLinkLazyQuery
>;
export type FdrProductLinkQueryResult = Apollo.QueryResult<
  FdrProductLinkQuery,
  FdrProductLinkQueryVariables
>;
