import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Form, IFormApi, IFormProps } from '@hotelplan/components.common.forms';
import BannerSearchFormSkeleton from 'components/domain/BannerSearchFormSkeleton.skeleton';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { FdrSearchControlTravelType } from 'fdr/components/candidate/fdr-search-control/fdr-search-control-travel-type';
import { useFdrSearchControlFormAction } from 'fdr/components/candidate/fdr-search-control/use-fdr-search-control-form-action';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { FdrNoResultsModal } from 'fdr/components/local/fdr-no-results-modal/fdr-no-results-modal';
import { useSrlFiltersResetOnTravelTypeChange } from 'fdr/components/local/fdr-search-form/use-srl-filters-reset-on-travel-type-change';
import { useFdrSearchFormRerender } from './use-fdr-search-form-rerender';

type TProp = React.PropsWithChildren<{
  postSubmit?: (state: IFdrSrlControlState) => void;
  FormComponent?: React.FC<IFormProps<IFdrSrlControlState>>;
  formProps?: IFormProps<IFdrSrlControlState> | {};
  formApiRef?: React.MutableRefObject<IFormApi<IFdrSrlControlState>>;
  extraVars?: any;
  enableOnReset?: boolean;
  searchType: ESearchType;
  handleCancel?: () => void;
  id?: string;
  searchControlState?: IFdrSrlControlState;
  className?: string;
}>;

const FormWrapper = styled(Form)(
  ({ theme }) => theme.forms.bannerForm
) as React.FC<IFormProps<IFdrSrlControlState>>;

export const FdrSearchForm = ({
  children,
  postSubmit,
  FormComponent = FormWrapper,
  formProps = {},
  extraVars = {},
  formApiRef: defaultFormApiRef,
  enableOnReset,
  searchType,
  handleCancel,
  id,
  searchControlState,
  className,
}: TProp) => {
  const {
    initialValues,
    loading,
    defaultLoading,
    formApiRef,
    onChange,
    onApply,
    onReset,
  } = useFdrSearchControlFormAction(
    searchType,
    extraVars,
    id,
    defaultFormApiRef
  );

  const formUpdateKey = useFdrSearchFormRerender();
  const refreshSRLSearchControls = useSrlFiltersResetOnTravelTypeChange();

  const isPdpOrl = [ESearchType.ORL, ESearchType.PDP].includes(searchType);
  const isOrl = ESearchType.ORL === searchType;
  const isSrl = ESearchType.SRL === searchType;

  // NOTE: this hook is needed to synchronize pdp search control
  // with orl search control
  useEffect(() => {
    if (!searchControlState || !isPdpOrl) return;
    formApiRef.current?.setValues(searchControlState);
  }, [searchControlState, isPdpOrl]);

  if (!initialValues || defaultLoading) {
    if (isPdpOrl) return null;
    return <BannerSearchFormSkeleton className={className} />;
  }

  const variableReset = enableOnReset ? onReset : () => void 0;

  return (
    <>
      <FdrNoResultsModal />
      <FormComponent
        key={formUpdateKey}
        formApiRef={formApiRef}
        initialValues={initialValues}
        onSubmit={(nextFormState: IFdrSrlControlState) => {
          if (!isSrl && !isOrl) {
            onApply(nextFormState);
          }

          if (isSrl) {
            refreshSRLSearchControls(nextFormState);
          }

          postSubmit?.(nextFormState);
        }}
        onChange={next => {
          formApiRef.current?.setValues(next);
          onChange(next);
        }}
        onReset={variableReset}
        {...formProps}
      >
        {children}
        <FdrSearchControlTravelType
          loading={loading}
          searchType={searchType}
          id={extraVars?.id}
          handleCancel={handleCancel}
          searchControlState={searchControlState}
          className={className}
        />
      </FormComponent>
    </>
  );
};
