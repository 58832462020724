import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import useFdrAddToHistory from 'fdr/components/local/fdr-history/useFdrAddToHistory';
import { useFdrOnFlightSearchApply } from 'fdr/components/local/fdr-search-link/use-fdr-on-flight-search-apply';
import { useFdrOnSearchApply } from 'fdr/components/local/fdr-search-link/use-fdr-on-search-apply';

export default function useFdrSearchControlApply(
  initialFormValues?: IFdrSrlControlState | null
) {
  const onHotelSearchApply = useFdrOnSearchApply(initialFormValues);
  const onFlightSearchApply = useFdrOnFlightSearchApply(initialFormValues);
  const addHistoryItem = useFdrAddToHistory();

  return function applySearch(formState: IFdrSrlControlState) {
    if (formState.type === 'HOTEL') {
      onHotelSearchApply(formState);
    } else {
      onFlightSearchApply(formState);
    }
    addHistoryItem(formState);
  };
}
