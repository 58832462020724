import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrSearchControlMobileSkeleton } from './fdr-search-control-mobile-skeleton';
import { SkeletonWhiteWrap } from './fdr-search-control-skeleton.styles';
import { IFdrSearchControlSkeletonProps } from './fdr-search-control-skeleton.types';
import { FdrSearchControlTabsSkeleton } from './fdr-search-control-tabs-skeleton';
import { FdrSearchInputsSkeleton } from './fdr-search-inputs-skeleton';

export const FdrSearchControlSkeleton: React.FC<IFdrSearchControlSkeletonProps> =
  ({ className, maxInputs, testId = `sc-loading`, ...tabsProps }) => {
    const { mobile } = useDeviceType();

    return mobile ? (
      <FdrSearchControlMobileSkeleton data-id={testId} />
    ) : (
      <SkeletonWhiteWrap className={className} data-id={testId}>
        <FdrSearchControlTabsSkeleton {...tabsProps} />
        <FdrSearchInputsSkeleton maxInputs={maxInputs} />
      </SkeletonWhiteWrap>
    );
  };
