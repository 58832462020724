import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import {
  EFdrFlightTravelType,
  T_Custom_TabsTravelTypesUnit,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';

export function getLangSpecificLink(
  language: string,
  linkConfig?: string | { [lang: string]: string }
): string {
  return typeof linkConfig === 'object'
    ? linkConfig[language]
    : linkConfig || '';
}

const GENERAL_TRAVEL_TYPES: T_Custom_TabsTravelTypesUnit[] = [
  FdrProductTravelType.Package,
  FdrProductTravelType.HotelOnly,
  EFdrFlightTravelType.Flight,
];

export const isGeneralTravelType = (travelType: T_Custom_TabsTravelTypesUnit) =>
  GENERAL_TRAVEL_TYPES.includes(travelType);
