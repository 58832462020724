import isEqual from 'fast-deep-equal';
import intersection from 'lodash/intersection';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useField, useFormContext } from '@hotelplan/components.common.forms';
import { useFdrAutocompleteCountContext } from '@hotelplan/fdr.regular.basis.fdr-autocomplete-count-context';
import { useFdrAutocompleteContext } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-context';
import { FdrAutocompleteOptionLoader } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-option';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  IFdrTravelDestinationState,
  TFdrProductSrlControlStateWithType,
} from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { allowedTravelDestinationItemTypes } from './fdr-destination-field.constants';

const TriggerInputCaptionContent = styled.span(
  ({ theme: { space, FONT_SIZE, colors } }) =>
    sx2CssThemeFn({
      display: 'flex',
      alignItems: 'center',
      paddingRight: space[3],
      ...FONT_SIZE.LARGE,
      color: colors.secondary,
    })
);

const FdrTravelDestinationTriggerInputCaption = (): ReactElement => {
  const autocompleteContext = useFdrAutocompleteContext<TFdrFlatDestination>();
  const autocompleteCountContext =
    useFdrAutocompleteCountContext<TFdrFlatDestination>();
  const [count, setCount] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { values: nextValues } =
    useFormContext<TFdrProductSrlControlStateWithType>();

  const [travelDestinationFieldValue] =
    useField<IFdrTravelDestinationState | null>(`legacy_travelDestination`);

  useEffect(() => {
    const countSummaryItem = autocompleteCountContext.items.find(item => {
      return (
        item.items.length === autocompleteContext.selectedItems.length &&
        intersection(
          autocompleteContext?.selectedItems.map(i => i.id),
          item.items.map(i => i.id)
        ).length === item.items.length
      );
    });

    if (countSummaryItem?.loading) {
      setLoading(true);
    } else {
      setLoading(false);

      if (
        countSummaryItem?.count >= 0 &&
        autocompleteContext.selectedItems?.length > 0
      ) {
        setCount(countSummaryItem.count);
      } else {
        setCount(null);
      }
    }
  }, [autocompleteContext.selectedItems, autocompleteCountContext.items]);

  const reloadAutocompleteCount = useCallback(() => {
    autocompleteCountContext.clear();

    const selectedItems = autocompleteContext.selectedItems.filter(item => {
      return (
        allowedTravelDestinationItemTypes.includes(item.__typename) && item.id
      );
    });

    selectedItems.forEach(item => {
      autocompleteCountContext.putItem({
        id: item.id,
        items: [item],
      });
    });

    if (
      selectedItems.length > 1 &&
      selectedItems.length === autocompleteContext.selectedItems.length
    ) {
      autocompleteCountContext.putItem({
        id: selectedItems.map(i => i.id).join(';'),
        items: selectedItems,
      });
    }
  }, [autocompleteContext.selectedItems]);

  useDeepCompareEffect(() => {
    reloadAutocompleteCount();
  }, [
    nextValues.extended_travelType.fdr,
    nextValues.travelRooms,
    nextValues.travelDates,
  ]);

  const syncAutocompleteContextSelectedItem = useCallback(() => {
    if (
      travelDestinationFieldValue.destinations?.length > 0 &&
      !isEqual(
        autocompleteContext.selectedItems.map(item => item.id),
        travelDestinationFieldValue.destinations.map(item => item.id)
      )
    ) {
      autocompleteContext.setSelected(travelDestinationFieldValue.destinations);
    }
  }, [
    autocompleteContext.selectedItems,
    travelDestinationFieldValue.destinations,
  ]);

  useEffect(() => {
    syncAutocompleteContextSelectedItem();
  }, [travelDestinationFieldValue.destinations]);

  return (
    <TriggerInputCaptionContent>
      {loading && <FdrAutocompleteOptionLoader />}
      {!loading && count !== null && count}
    </TriggerInputCaptionContent>
  );
};

export default FdrTravelDestinationTriggerInputCaption;
