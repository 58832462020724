import React, { useMemo } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { ESearchType } from './destination/fdr-destination-field.types';
import {
  additionalTravelTypes,
  mainTravelTypes,
  productTravelTypes,
} from './fdr-search-control.constants';
import { FdrSearchControlFormLayout } from './form-layout';
import { FdrTravelTypeField } from './travel-type/fdr-travel-type-field';

export const FdrSearchControlTravelTypeContainer: React.FC<{
  children?: React.ReactNode;
  searchType: ESearchType;
  className?: string;
}> = props => {
  const { children, searchType, className } = props;
  const { mobile } = useDeviceType();

  const autoSubmitOnChange = useMemo(
    () =>
      [ESearchType.SRL, ESearchType.ORL, ESearchType.WISHLIST].includes(
        searchType
      ) && !mobile,
    [searchType, mobile]
  );

  const travelTypes = useMemo(() => {
    return [
      ESearchType.SRL,
      ESearchType.PDP,
      ESearchType.ORL,
      ESearchType.WISHLIST,
    ].includes(searchType)
      ? productTravelTypes
      : [...mainTravelTypes, ...(mobile ? [] : additionalTravelTypes)];
  }, [searchType, mobile]);

  const dropDownTravelTypes = useMemo(() => {
    return [
      ESearchType.SRL,
      ESearchType.PDP,
      ESearchType.ORL,
      ESearchType.WISHLIST,
    ].includes(searchType)
      ? []
      : mobile
      ? additionalTravelTypes
      : [];
  }, [searchType, mobile]);

  return (
    <FdrSearchControlFormLayout
      travelTypes={
        <FdrTravelTypeField
          mainTravelTypes={travelTypes}
          dropDownTravelTypes={dropDownTravelTypes}
          withAutoSubmitOnChange={autoSubmitOnChange}
        />
      }
      className={className}
    >
      {children}
    </FdrSearchControlFormLayout>
  );
};
