import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrAutocompleteCountSource } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-count-source';
import {
  FdrTravelDestinationSuggestion,
  IFdrTravelDestinationDisableableProps,
  TFdrFlatDestination,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import FdrGenericDestinationWithCount from 'fdr/components/candidate/fdr-search-control/destination/fdr-generic-destination-with-count';
import {
  IFdrProductSrlControlState,
  TFdrProductSrlControlStateWithType,
} from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { U_Extended_TravelType } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { trackSelectDestination } from 'fdr/components/candidate/fdr-top-destination-autocomplete-suggestions/fdr-select-destination.tracking';
import useFdrTopDestinationAutocompleteIds from 'fdr/components/candidate/fdr-top-destination-autocomplete-suggestions/use-fdr-top-destination-autocomplete-ids';
import FdrSearchHistoryTopDestinationComboSuggestions from 'fdr/components/local/fdr-search-history/fdr-search-history-top-destination-combo-suggestions';
import {
  FdrTextSearchAutocompleteDocument,
  FdrTextSearchAutocompleteQuery,
  FdrTextSearchAutocompleteQueryVariables,
} from 'fdr/schemas/query/search/autocomplete/fdr-text-search-autocomplete.generated';
import FdrTravelDestinationFieldCaption from './fdr-destination-field-caption';
import { FdrDestinationFieldWithCount } from './fdr-destination-field-with-count';
import { allowedTravelDestinationItemTypes } from './fdr-destination-field.constants';
import { searchTypeMapper } from './fdr-destination-field.util';
import FdrTravelDestinationHotelOnlySuggestion from './suggestion/fdr-travel-destination-hotel-only-suggestion';
import { useFdrTravelDestinationAutocompleteHotelOnlyDestinations } from './suggestion/use-fdr-travel-destination-autocomplete-hotel-only-destinations';

type TProp = IFdrTravelDestinationDisableableProps & {
  customLabel: React.ReactNode;
  objectId?: string;
  searchControlState?: IFdrProductSrlControlState;
  searchType: ESearchType;
};

export function FdrDestinationField({
  disabled,
  customLabel,
  objectId,
  searchControlState,
  searchType,
}: TProp) {
  const pageType = usePageType<EPageType>();
  const { mobile } = useDeviceType();
  const [, { language }] = useTranslation();

  const isHomePage = pageType === EPageType.Home;

  const [travelType] =
    useField<U_Extended_TravelType | null>(`extended_travelType`);

  const topDestinationIds = useFdrTopDestinationAutocompleteIds(travelType);
  const [topDestinationsShown, setTopDestinationShown] =
    useState<boolean | null>(null);

  const hotelOnlyDestinations =
    useFdrTravelDestinationAutocompleteHotelOnlyDestinations();

  const onTopDestinationsChange = (shown: boolean) => {
    setTopDestinationShown(shown);
  };

  const renderSuggestion = useCallback(
    (item: TFdrFlatDestination, query: string) => {
      if (
        hotelOnlyDestinations.includes(item.id) &&
        travelType.fdr !== FdrProductTravelType.HotelOnly
      ) {
        return (
          <FdrTravelDestinationHotelOnlySuggestion item={item} query={query} />
        );
      } else {
        return <FdrTravelDestinationSuggestion item={item} query={query} />;
      }
    },
    [hotelOnlyDestinations, travelType]
  );

  return (
    <>
      <FdrDestinationFieldWithCount>
        <FdrGenericDestinationWithCount<
          FdrTextSearchAutocompleteQuery,
          FdrTextSearchAutocompleteQueryVariables
        >
          disabled={disabled}
          customLabel={customLabel}
          topDestinationsShown={topDestinationsShown}
          queryDocument={FdrTextSearchAutocompleteDocument}
          dataMapper={(data: FdrTextSearchAutocompleteQuery) => {
            if (!data) return null;

            return {
              page: data.fdrTextAutocomplete.pagination,
              destinations: data.fdrTextAutocomplete.itemList,
            };
          }}
          variablesMapper={variables => {
            return {
              ...variables,
              searchType: searchTypeMapper(searchType),
            };
          }}
          caption={
            <FdrTravelDestinationFieldCaption
              searchControlState={searchControlState}
              objectId={objectId}
            />
          }
          countDataSource={
            <FdrAutocompleteCountSource<TFdrFlatDestination>
              autocompleteItemFilter={(items: TFdrFlatDestination[]) => {
                return items.filter(
                  item =>
                    allowedTravelDestinationItemTypes.includes(
                      item.__typename
                    ) && item.id
                );
              }}
              travelDestinations={
                (searchControlState as TFdrProductSrlControlStateWithType)
                  ?.legacy_travelDestination?.destinations
              }
              objectId={objectId}
            />
          }
          onSelect={(item, _, popupControl) => {
            const isHotelOnlyDestination =
              !topDestinationsShown &&
              hotelOnlyDestinations.includes(item.id) &&
              travelType.fdr === FdrProductTravelType.Package;

            const isTopDestination =
              topDestinationsShown && topDestinationIds.includes(item.id);

            trackSelectDestination(
              item.name,
              isHomePage ? 'home' : 'srl',
              isTopDestination ? 'top destination' : 'all destinations',
              isTopDestination ? topDestinationIds.indexOf(item.id) + 1 : 1,
              language
            );

            if (!mobile && (isTopDestination || isHotelOnlyDestination)) {
              popupControl.close();
            }
          }}
          renderSuggestion={renderSuggestion}
        >
          <FdrSearchHistoryTopDestinationComboSuggestions
            travelType={travelType}
            onTopDestinationsChange={onTopDestinationsChange}
          />
        </FdrGenericDestinationWithCount>
      </FdrDestinationFieldWithCount>
    </>
  );
}
