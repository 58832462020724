import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import CustomLabel from 'components/domain/CustomLabel';
import { FdrDestinationField } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { useFdrStickyContext } from 'fdr/components/candidate/fdr-search-control/fdr-search-sticky-context';
import { IFdrProductSrlControlState } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { FdrCommonFormLayout } from 'fdr/components/local/fdr-common-form-layout/fdr-common-form-layout';
import { FdrMixedSearchDropdown } from 'fdr/components/local/fdr-search-dropdown/fdr-mixed-search-dropdown/fdr-mixed-search-dropdown';
import { productTravelTypes } from './fdr-search-control.constants';
import FdrTravelRoomsFieldContainer from './travel-rooms/fdr-travel-rooms-field-container';
import { useFdrFourTravelRooms } from './travel-rooms/use-fdr-four-travel-rooms';
import FdrTravelTypesContainer from './travel-type/fdr-travel-types-container';

type TProps = {
  handleCancel?: () => void;
  id?: string;
  searchType: ESearchType;
  searchControlState?: IFdrSrlControlState;
};

export function FdrSearchControlFields({
  handleCancel,
  id,
  searchType,
  searchControlState,
}: TProps) {
  const [t] = useTranslation('common');
  const { isValid: isTravelRoomsValid, isEmpty } = useFdrFourTravelRooms();
  const { mobile } = useDeviceType();
  const { sticky, showSearchControls } = useFdrStickyContext();
  const isShowStickySearchControl = sticky && showSearchControls;
  const hideLabel = isShowStickySearchControl && 'hide';

  return (
    <FdrCommonFormLayout
      sticky={isShowStickySearchControl}
      travelTypes={
        isShowStickySearchControl && !mobile ? (
          <FdrTravelTypesContainer mainTravelTypes={productTravelTypes} />
        ) : null
      }
      travelDestination={
        ![ESearchType.ORL, ESearchType.WISHLIST].includes(searchType) && (
          <FdrDestinationField
            customLabel={
              <CustomLabel htmlFor="travelDestination" className={hideLabel}>
                {t('search_form_label.travel_destination')}
              </CustomLabel>
            }
            disabled={!isTravelRoomsValid}
            searchControlState={
              searchControlState as IFdrProductSrlControlState
            }
            objectId={id}
            searchType={searchType}
          />
        )
      }
      travelDates={
        <FdrMixedSearchDropdown
          disabled={!isTravelRoomsValid}
          className={hideLabel}
        />
      }
      travelRooms={
        <FdrTravelRoomsFieldContainer
          sticky={isShowStickySearchControl}
          customLabel={
            <CustomLabel htmlFor={`travelers`} className={hideLabel}>
              {t('search_form_label.travelers')}
            </CustomLabel>
          }
        />
      }
      isSubmitAllowed={isTravelRoomsValid && !isEmpty}
      handleCancel={handleCancel}
    />
  );
}
