import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const TabControlsContainer = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    ml: [null, -3],
  })
);

export const TravelTypeIcon = styled(Icon)(({ theme: { space } }) => ({
  marginRight: space[1],
}));

export const TravelTypePlusDelimiter = styled.span(({ theme: { space } }) => ({
  padding: `0 ${space[2]}`,
}));
