import { useTransfer } from '@hotelplan/fdr.lib.router.push';
import { useFdrNotOrlModal } from 'fdr/components/local/fdr-search-link/use-fdr-not-orl-modal';
import { useFdrSearchLinkLazyQuery } from 'fdr/schemas/query/search/search-link/fdr-search-link.generated';

export function useFdrGetSearchLink() {
  const { transfer } = useTransfer();

  const { showModal } = useFdrNotOrlModal();

  return useFdrSearchLinkLazyQuery({
    onCompleted(linkData) {
      const url = linkData.fdrProductSearchResultListPage.webMeta.link;

      if (!url?.url) {
        showModal();
      } else {
        transfer(url);
      }
    },
    fetchPolicy: `cache-and-network`,
  });
}
