import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const DropDownMobileWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'background',
    position: 'absolute',
    top: '100%',
    right: 0,
    boxShadow: 'large',
    zIndex: 1,
  })
);

export const MoreButtonContainer = styled.li({
  position: 'relative',
});

export const TravelTypeMoreButton = styled.button(
  sx2CssThemeFn({
    width: ['100%', 'auto'],
  })
);
