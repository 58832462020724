import styled from 'styled-components';
import { FakeInput } from '@hotelplan/components.common.inputs';
import { FdrTravelDestinationInput } from 'fdr/components/candidate/fdr-search-control/destination/input/fdr-travel-destination-input';

export const SearchDestinationFakeInput = styled(FakeInput)(
  ({ theme }) => theme.inputs.searchControlField
);

export const SearchDestinationInput = styled(FdrTravelDestinationInput)<{
  customLabel?: React.ReactNode;
}>(({ theme, customLabel }) => ({
  ...theme.inputs.searchControlField,
  '.autocomplete-dropdown': {
    top: Boolean(customLabel) ? '28px' : 0,
  },
}));
