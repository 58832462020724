import styled from 'styled-components';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { CSSObject } from 'styled-components';

const linkStyles = (outlineColor: string): CSSObject => ({
  width: '100%',
  marginBottom: '-1px',
  ':focus-visible': {
    '.tabControls': {
      outline: `auto 2px`,
      outlineOffset: '-1px',
      outlineColor,
    },
  },
});

export const FdrRouteTravelTypeBaseLink = styled(FdrRoute)(
  ({ theme: { colors } }) => linkStyles(colors.defaultText)
);
export const FdrTravelTypeBaseLink = styled(BsLink)(({ theme: { colors } }) =>
  linkStyles(colors.defaultText)
);
