import React from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import { useSearchState } from '@hotelplan/fdr.lib.search.with-state';
import { FdrAutocompleteCountProvider } from '@hotelplan/fdr.regular.basis.fdr-autocomplete-count-context';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { FdrProductTravelType, FdrSorting } from '@hotelplan/supergraph-api';
import {
  fdrFlatDestinationToDestinationsCriteria,
  fdrSrlFormToCriteriaInput,
} from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-form-to-criteria-input.mapper';
import { IFdrSRLState } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { isTravelDestinationChanged } from 'fdr/components/local/fdr-search-form/fdr-search-form.utils';
import {
  FdrProductSearchCountDocument,
  FdrProductSearchCountQuery,
  FdrProductSearchCountQueryVariables,
} from 'fdr/schemas/query/search/autocomplete/fdr-product-search-count.generated';
import { FdrTravelDestinationFieldContent } from './fdr-destination-field-with-count.styles';
import { useFdrTravelDestinationAutocompleteHotelOnlyDestinations } from './suggestion/use-fdr-travel-destination-autocomplete-hotel-only-destinations';

export function FdrDestinationFieldWithCount({
  children,
}: React.PropsWithChildren) {
  const { values: formValues } = useFormContext<IFdrSrlControlState>();

  const { state } = useSearchState<IFdrSRLState>();

  const hotelOnlyDestinations =
    useFdrTravelDestinationAutocompleteHotelOnlyDestinations();

  return (
    <FdrAutocompleteCountProvider<
      TFdrFlatDestination,
      FdrProductSearchCountQuery,
      FdrProductSearchCountQueryVariables
    >
      queryDocument={FdrProductSearchCountDocument}
      dataMapper={data => {
        return (
          data.fdrProductSearchResult?.searchResult?.pagination?.total || 0
        );
      }}
      variablesMapper={items => {
        const criteria = fdrSrlFormToCriteriaInput(
          formValues,
          state?.filters,
          isTravelDestinationChanged(state, formValues) && state?.subGeoFilters // do not use sub-geo-filters for next destination count
        );

        if (
          hotelOnlyDestinations.length &&
          items.filter(item => hotelOnlyDestinations.includes(item.id))
            .length === items.length
        ) {
          criteria.travelType = FdrProductTravelType.HotelOnly;
        }

        return {
          criteria: {
            ...criteria,
            destinations: {
              ...fdrFlatDestinationToDestinationsCriteria(items),
              ...(criteria.destinations?.subGeoPublicIds
                ? { subGeoPublicIds: criteria.destinations?.subGeoPublicIds }
                : {}),
            },
            sorting: FdrSorting.Price,
          },
        };
      }}
    >
      <FdrTravelDestinationFieldContent>
        {children}
      </FdrTravelDestinationFieldContent>
    </FdrAutocompleteCountProvider>
  );
}
