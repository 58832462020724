import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { TabControls } from '@hotelplan/components.common.tabs';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { T_Custom_TabsTravelTypesUnit } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import {
  DropDownMobileWrapper,
  MoreButtonContainer,
  TravelTypeMoreButton,
} from './fdr-travel-type-control-container.styles';
import { FdrTravelTypeLink } from './fdr-travel-type-link';
import { fdrMapTravelTypeToTravelTypeData } from './fdr-travel-type.mappers';
import { getLangSpecificLink } from './fdr-travel-type.utils';

interface ITravelTypeButtonProps {
  onClick: () => void;
  checked?: boolean;
  text: string;
}

const MoreButton: React.FC<ITravelTypeButtonProps> = ({
  onClick,
  checked,
  text,
}) => {
  return (
    <TravelTypeMoreButton
      onClick={onClick}
      data-id="travelTypesMoreButton"
      type="button"
    >
      <TabControls icon="more" checked={checked}>
        {text}
      </TabControls>
    </TravelTypeMoreButton>
  );
};

interface ITravelTypeControlContainer {
  dropDownTravelTypes: T_Custom_TabsTravelTypesUnit[];
  onSelectTravelType?: (travelType: T_Custom_TabsTravelTypesUnit) => void;
}
export const FdrTravelTypeControlContainer: React.FC<ITravelTypeControlContainer> =
  ({ dropDownTravelTypes, onSelectTravelType }) => {
    const [t, i18n] = useTranslation('common');
    const [isDropdownOpen, , closeDropdown, toggle] = useToggleState(false);
    const moreButtonContainerRef = useRef<HTMLLIElement>(null);
    useOnClickOutside<HTMLLIElement>(moreButtonContainerRef, closeDropdown);
    const { channelType } = useAuthentication();

    return (
      <MoreButtonContainer ref={moreButtonContainerRef}>
        <MoreButton
          onClick={toggle}
          checked={isDropdownOpen}
          text={t('more')}
        />
        {isDropdownOpen && (
          <DropDownMobileWrapper>
            {dropDownTravelTypes.map(travelType => {
              const travelTypeData = fdrMapTravelTypeToTravelTypeData(
                travelType,
                channelType
              );
              return (
                travelTypeData && (
                  <React.Fragment key={travelType}>
                    <FdrTravelTypeLink
                      testId={travelTypeData.testId}
                      link={getLangSpecificLink(
                        i18n.language,
                        travelTypeData.link
                      )}
                      isExternal={travelTypeData.isExternal}
                      icon={travelTypeData.icon}
                      label={t(travelTypeData.label)}
                      onClick={() => onSelectTravelType?.(travelType)}
                    />
                  </React.Fragment>
                )
              );
            })}
          </DropDownMobileWrapper>
        )}
      </MoreButtonContainer>
    );
  };
