import type { DocumentNode } from 'graphql';
import { useQuery } from '@hotelplan/fdr.lib.apollo.query';
import { normalizeDocumentNode } from '@hotelplan/libs.graphql';
import { U_Extended_TravelType } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { fdrMapFlightSRLSearchControlValuesToFlightFormState } from 'fdr/components/local/fdr-flight-search';
import { FdrFlightHomeFlightSearchControlsDocument } from 'fdr/schemas/query/flight/fdr-flight-home-flight-search-controls.generated';
import { FdrGeoFlightSearchControlsDocument } from 'fdr/schemas/query/geo/search-controls/fdr-geo-flight-search-controls.generated';
import { FdrHomeFlightSearchControlsDocument } from 'fdr/schemas/query/home/search-controls/fdr-home-flight-search-controls.generated';
import { FdrPdpSearchControlsDocument } from 'fdr/schemas/query/pdp/search-controls/fdr-pdp-search-controls.generated';
import { FdrSearchControlsDocument } from 'fdr/schemas/query/search/search-controls/fdr-search-controls.generated';
import { FdrThemeOverviewFlightSearchControlsDocument } from 'fdr/schemas/query/theme-overview/search-controls/fdr-theme-overview-flight-search-controls.generated';
import { FdrThemeFlightSearchControlsDocument } from 'fdr/schemas/query/theme/search-controls/fdr-theme-flight-search-controls.generated';
import { ESearchType } from './destination/fdr-destination-field.types';
import { EControlKey } from './fdr-search-control.types';

const searchTypeCfg = new Map<
  ESearchType,
  { query: DocumentNode; resultKey: EControlKey }
>([
  [
    ESearchType.HOME,
    { query: FdrHomeFlightSearchControlsDocument, resultKey: EControlKey.HOME },
  ],
  [
    ESearchType.THEME_OVERVIEW,
    {
      query: FdrThemeOverviewFlightSearchControlsDocument,
      resultKey: EControlKey.THEME_OVERVIEW,
    },
  ],
  [
    ESearchType.THEME,
    {
      query: FdrThemeFlightSearchControlsDocument,
      resultKey: EControlKey.THEME,
    },
  ],
  [
    ESearchType.GEO,
    {
      query: FdrGeoFlightSearchControlsDocument,
      resultKey: EControlKey.GEO,
    },
  ],
  [
    ESearchType.FLIGHT,
    {
      query: FdrFlightHomeFlightSearchControlsDocument,
      resultKey: EControlKey.FLIGHT_HOME,
    },
  ],
  // SRL query using here only as a fallback. It never should be called, as SRL doesn't have flight form
  [
    ESearchType.SRL,
    { query: FdrSearchControlsDocument, resultKey: EControlKey.SRL },
  ],
  // ORL query using here only as a fallback. It never should be called, as PDP doesn't have flight form
  [
    ESearchType.ORL,
    { query: FdrPdpSearchControlsDocument, resultKey: EControlKey.PDP },
  ],
  // PDP query using here only as a fallback. It never should be called, as PDP doesn't have flight form
  [
    ESearchType.PDP,
    { query: FdrPdpSearchControlsDocument, resultKey: EControlKey.PDP },
  ],
]);

export function useFdrFlightSearchControlValues<
  TGetFlightValuesQuery,
  TGetFlightValuesVariables
>(
  searchType: ESearchType,
  skip?: boolean,
  variables?: TGetFlightValuesVariables,
  onCompleted?: (extendedTravelType: U_Extended_TravelType) => void
) {
  const { query, resultKey } = searchTypeCfg.get(searchType) || {};

  const { data, loading } = useQuery<
    TGetFlightValuesQuery,
    TGetFlightValuesVariables
  >(normalizeDocumentNode(query), {
    notifyOnNetworkStatusChange: true,
    variables,
    onCompleted(res) {
      return onCompleted(
        fdrMapFlightSRLSearchControlValuesToFlightFormState(
          res[resultKey].flightSearchControls.controls
        ).extended_travelType
      );
    },
    skip,
  });

  return {
    loading,
    data: data
      ? fdrMapFlightSRLSearchControlValuesToFlightFormState(
          data[resultKey].flightSearchControls.controls
        )
      : undefined,
  };
}
