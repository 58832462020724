import { useTransfer } from '@hotelplan/fdr.lib.router.push';
import { useFdrProductLinkLazyQuery } from 'fdr/schemas/query/product/fdr-product-link.generated';

export function useFdrGetProductLink() {
  const { transfer } = useTransfer();

  return useFdrProductLinkLazyQuery({
    onCompleted(linkData) {
      const url = linkData.fdrProduct?.webMeta.offersWebMeta?.link;

      if (url?.url) {
        transfer(url);
      }
    },
    fetchPolicy: `cache-and-network`,
  });
}
