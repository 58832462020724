import React from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { FdrSearchControlFields } from 'fdr/components/candidate/fdr-search-control/fdr-search-control-fields';
import { FdrSearchControlFormType } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { FdrFlightFormContainer } from 'fdr/components/local/fdr-flight-search/form/fdr-flight-form-container';
import { FdrSearchControlTravelTypeContainer } from './fdr-search-control-travel-type-container';
import { FdrSearchControlInputsSkeleton } from './skeleton';

const PageSearchControlForm: React.FC<{
  searchType: ESearchType;
  searchControlState?: IFdrSrlControlState;
  id?: string;
  handleCancel?: () => void;
  postSubmit?: (state: IFdrSrlControlState) => void;
}> = ({ searchType, id, handleCancel, searchControlState }) => {
  const [formType] = useField<FdrSearchControlFormType>('type' as const);

  if (formType === 'HOTEL')
    return (
      <FdrSearchControlFields
        searchType={searchType}
        id={id}
        handleCancel={handleCancel}
        searchControlState={searchControlState}
      />
    );

  return (
    <FdrFlightFormContainer withCustomLabels handleCancel={handleCancel} />
  );
};

export function FdrSearchControlTravelType({
  loading,
  searchType,
  id,
  handleCancel,
  searchControlState,
  className,
}: {
  loading: boolean;
  searchType: ESearchType;
  id?: string;
  handleCancel?: () => void;
  postSubmit?: (state: IFdrSrlControlState) => void;
  searchControlState?: IFdrSrlControlState;
  className?: string;
}) {
  return (
    <FdrSearchControlTravelTypeContainer
      searchType={searchType}
      className={className}
    >
      {loading ? (
        <FdrSearchControlInputsSkeleton />
      ) : (
        <PageSearchControlForm
          searchType={searchType}
          id={id}
          handleCancel={handleCancel}
          searchControlState={searchControlState}
        />
      )}
    </FdrSearchControlTravelTypeContainer>
  );
}
