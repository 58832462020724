import { useTranslation } from 'next-i18next';
import React from 'react';
import { FdrTravelDestinationLabelCaption } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import {
  HotelOnlyLabel,
  HotelOnlyLabelText,
} from './fdr-travel-destination-hotel-only-suggestion.styles';
import { IFdrTravelDestinationHotelOnlySuggestionProps } from './fdr-travel-destination-hotel-only-suggestion.types';
import { FdrTravelDestinationSuggestion } from './fdr-travel-destination-suggestion';

const FdrTravelDestinationHotelOnlySuggestion: React.FC<IFdrTravelDestinationHotelOnlySuggestionProps> =
  ({ item, query }) => {
    const [t] = useTranslation('common');

    return (
      <>
        <FdrTravelDestinationSuggestion
          item={{ ...item, countState: { count: 0, loading: false } }}
          query={query}
        />
        <HotelOnlyLabel>
          <HotelOnlyLabelText>
            {t('onlyHotelOffersAvailable')}
          </HotelOnlyLabelText>
          <FdrTravelDestinationLabelCaption item={item} />
        </HotelOnlyLabel>
      </>
    );
  };

export default FdrTravelDestinationHotelOnlySuggestion;
