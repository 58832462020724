import styled from 'styled-components';

export const HotelOnlyLabel = styled.div(
  ({ theme: { fontSizes, colors } }) => ({
    display: 'flex',
    fontSize: fontSizes[2],
    color: colors.darkGray,
  })
);

export const HotelOnlyLabelText = styled.span({
  marginLeft: '38px',
});
