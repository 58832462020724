import { isEqual } from 'lodash';
import {
  FdrProductSearchCriteriaInput,
  FdrSorting,
} from '@hotelplan/supergraph-api';
import { fdrSrlFormToCriteriaInput } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-form-to-criteria-input.mapper';
import {
  IFdrSRLState,
  TFdrProductSrlControlStateWithType,
} from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';

export function getCriteria(
  state: IFdrSRLState,
  formState: IFdrSrlControlState
): FdrProductSearchCriteriaInput {
  const productSorting = state?.productSorting as unknown as FdrSorting;

  // if destination is changed, we should not use prev sub geo filters
  const applySubGeoFilters = isTravelDestinationChanged(state, formState);

  return {
    ...fdrSrlFormToCriteriaInput(
      { ...state.searchControl, ...formState },
      state.filters,
      applySubGeoFilters && state.subGeoFilters
    ),
    ...(productSorting ? { sorting: productSorting } : {}),
  };
}

export function isTravelDestinationChanged(
  state: IFdrSRLState,
  formState: IFdrSrlControlState
): boolean {
  return isEqual(
    state?.searchControl?.legacy_travelDestination.ids,
    (formState as TFdrProductSrlControlStateWithType)?.legacy_travelDestination
      .ids
  );
}
