import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { track } from '@hotelplan/libs.tracking-events';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import { getShortInformation } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.util';
import { useFdrTravelDestinationAutocompleteHotelOnlyDestinations } from 'fdr/components/candidate/fdr-search-control/destination/suggestion/use-fdr-travel-destination-autocomplete-hotel-only-destinations';
import { fdrFlatDestinationToDestinationsCriteria } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-form-to-criteria-input.mapper';
import {
  IFdrTravelDestinationState,
  TFdrProductSrlControlStateWithType,
} from 'fdr/components/candidate/fdr-search/types/search-form.types';
import {
  SearchDestinationFakeInput,
  SearchDestinationInput,
} from './fdr-travel-destination-field.styles';
import {
  IFdrTravelDestinationDisableableProps,
  IFdrTravelDestinationInputProps,
} from './fdr-travel-destination-field.types';

type ITravelDestinationProps = Omit<
  IFdrTravelDestinationInputProps,
  'value' | 'placeholder' | 'label' | 'onChange'
> &
  IFdrTravelDestinationDisableableProps & {
    renderCaption?(): ReactNode;
    topDestinationsShown?: boolean;
  };

function trackHotelOnlyDestinationClick() {
  track({
    event: `🚀 click.switch`,
    eventMetadata: {
      target: 'hotels-only',
      component: 'autosuggest',
    },
  });
}

export const FdrTravelDestinationField: React.FC<ITravelDestinationProps> =
  props => {
    const [t] = useTranslation(['search', 'common']);

    const { values, setValues } =
      useFormContext<TFdrProductSrlControlStateWithType>();

    const hotelOnlyDestinations =
      useFdrTravelDestinationAutocompleteHotelOnlyDestinations();

    const { destinations: originalDestination } =
      values.legacy_travelDestination ||
      ({
        destinations: [],
      } as IFdrTravelDestinationState);

    const destinations = originalDestination || [];

    function setNextDestinations(
      nextDestinations: TFdrFlatDestination[],
      travelTypeToHotel?: boolean
    ) {
      setValues({
        ...values,
        legacy_travelDestination: {
          ...values.legacy_travelDestination,
          ids: fdrFlatDestinationToDestinationsCriteria(nextDestinations),
          destinations: nextDestinations,
        },
        ...(travelTypeToHotel
          ? { extended_travelType: { fdr: FdrProductTravelType.HotelOnly } }
          : {}),
      });
    }

    const onChangeDestinations = (
      nextDestinations: TFdrFlatDestination[]
    ): void => {
      if (nextDestinations?.length === 1) {
        const nextItem = nextDestinations[0];

        const isHotelOnlyDestination =
          !props.topDestinationsShown &&
          hotelOnlyDestinations.includes(nextItem.id) &&
          values?.extended_travelType.fdr === FdrProductTravelType.Package;

        if (isHotelOnlyDestination) {
          setNextDestinations(nextDestinations, true);
          trackHotelOnlyDestinationClick();
          return;
        }
      }

      setNextDestinations(nextDestinations);
    };

    if (props.disabled) {
      return (
        <SearchDestinationFakeInput
          icon={'planet'}
          placeholder={t('search:destination.placeholder')}
          value={getShortInformation(t, destinations, props.limit)}
          className={props.className}
        />
      );
    }

    return (
      <SearchDestinationInput
        label={t('search:destination.label')}
        placeholder={t('search:destination.placeholder')}
        value={destinations}
        onChange={onChangeDestinations}
        customLabel={props.customLabel}
        className="travelDestinationInput"
        {...props}
      >
        {props.children}
      </SearchDestinationInput>
    );
  };
