import React from 'react';
import { FdrHighlightText } from '@hotelplan/fdr.regular.basis.fdr-highlight';
import { FdrTravelDestinationLabel } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { IFdrTravelDestinationSuggestionProps } from './fdr-travel-destination-suggestion.types';

export const FdrTravelDestinationSuggestion: React.FC<IFdrTravelDestinationSuggestionProps> =
  ({ item, query }) => {
    return (
      <FdrTravelDestinationLabel
        item={item}
        label={<FdrHighlightText text={item.canonicalName} substring={query} />}
      />
    );
  };
