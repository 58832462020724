import React, { useContext, useState } from 'react';

interface IFdrStickyContext {
  sticky: boolean;
  stickyFilters: boolean;
  showSearchControls: boolean;
  setSticky: React.Dispatch<React.SetStateAction<boolean>>;
  setStickyFilters: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSearchControls: React.Dispatch<React.SetStateAction<boolean>>;
}

const FdrStickyContext = React.createContext<IFdrStickyContext>({
  sticky: false,
  stickyFilters: false,
  showSearchControls: false,
  setSticky: () => void false,
  setStickyFilters: () => void false,
  setShowSearchControls: () => void false,
});

export const FdrStickyContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [sticky, setSticky] = useState<boolean>(false);
  const [stickyFilters, setStickyFilters] = useState<boolean>(false);
  const [showSearchControls, setShowSearchControls] = useState<boolean>(false);

  return React.createElement(
    FdrStickyContext.Provider,
    {
      value: {
        sticky,
        showSearchControls,
        setSticky,
        setShowSearchControls,
        stickyFilters,
        setStickyFilters,
      },
    },
    children
  );
};

export function useFdrStickyContext() {
  return useContext(FdrStickyContext);
}
