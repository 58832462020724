import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { IFdrSearchInputsSkeletonProps } from './fdr-search-control-skeleton.types';

export const FdrSearchInputsSkeleton: React.FC<IFdrSearchInputsSkeletonProps> =
  ({ maxInputs = 3 }) => {
    const { mobile } = useDeviceType();

    return (
      <>
        {!mobile && (
          <PlaceholderLoader
            uid="searchInputsSkeleton"
            height={100}
            width={1170}
            style={{ width: '100%', height: '100%', display: 'block' }}
          >
            {maxInputs === 3 && (
              <>
                <rect x="31" y="20" width="311" height="60" />
                <rect x="354" y="20" width="311" height="60" />
                <rect x="677" y="20" width="311" height="60" />
              </>
            )}
            {maxInputs === 2 && (
              <>
                <rect x="31" y="20" width="467" height="60" />
                <rect x="515" y="20" width="467" height="60" />
              </>
            )}

            <rect x="1000" y="20" width="140" height="60" rx="5" />
          </PlaceholderLoader>
        )}
      </>
    );
  };
