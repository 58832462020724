import { useTranslation } from 'next-i18next';
import React from 'react';
import { FdrEmptyResultsModal } from 'fdr/components/local/fdr-empty-results/fdr-empty-results';
import { useFdrNotOrlModal } from 'fdr/components/local/fdr-search-link/use-fdr-not-orl-modal';

export function FdrNoResultsModal() {
  const [t] = useTranslation('results');

  const notOrlModal = useFdrNotOrlModal();

  return (
    <FdrEmptyResultsModal
      isModalShowed={notOrlModal.isModalVisible}
      onRenewSearch={notOrlModal.onRenewSearch}
      onClose={notOrlModal.onClose}
      title={t('noOrlModal.title')}
      description={t('noOrlModal.description', {
        productName: notOrlModal.product || '',
      })}
      buttonContent={t('noOrlModal.buttonContent')}
    />
  );
}
