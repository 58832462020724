import _ from 'lodash';
import React, { ReactElement, useCallback } from 'react';
import { withLazyHydrate } from '@hotelplan/components.common.lazy-render';
import { useFdrAutocompleteCountContext } from '@hotelplan/fdr.regular.basis.fdr-autocomplete-count-context';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { TFdrGenericTravelDestinationWithCountProps } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import FdrGenericDestinationField from 'fdr/components/candidate/fdr-search-control/destination/fdr-generic-destination-field';
import { flattenFdrAutocomplete } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-response-to-state.mapper';
import { FdrAutocompleteItemFragment } from 'fdr/schemas/query/search/autocomplete/fdr-text-search-autocomplete.generated';

const FdrGenericDestinationWithCount = <
  TData extends object,
  TVariables extends { text: string }
>(
  props: React.PropsWithChildren<
    TFdrGenericTravelDestinationWithCountProps<TData, TVariables>
  >
): ReactElement => {
  const countCtx = useFdrAutocompleteCountContext<TFdrFlatDestination>();

  const suggestionMapper = useCallback(
    (destination: FdrAutocompleteItemFragment) => {
      const flatAutocomplete = flattenFdrAutocomplete(destination);

      if (!flatAutocomplete) {
        return null;
      }

      const destinationIds = [flatAutocomplete.id];
      const contextDestination = countCtx.items.find(item => {
        return (
          destinationIds.length === item.items.length &&
          _.intersection(
            item.items.map(i => i.id),
            destinationIds
          ).length === item.items.length
        );
      });

      return {
        ...flatAutocomplete,
        ...(contextDestination && {
          countState: {
            count: contextDestination.count,
            loading: contextDestination.loading,
          },
        }),
      };
    },
    [countCtx.items]
  );

  return (
    <FdrGenericDestinationField<TData, TVariables>
      {...props}
      suggestionMapper={suggestionMapper}
    />
  );
};

export default withLazyHydrate(FdrGenericDestinationWithCount, {
  whenIdle: true,
}) as typeof FdrGenericDestinationWithCount;
