import { useTranslation } from 'next-i18next';
import React from 'react';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import {
  CancelButton,
  SearchControlInternalContainer,
  SubmitButton,
} from './fdr-common-form-layout.styles';
import { IFdrCommonFormLayoutProps } from './fdr-common-form-layout.types';

function stopPropagationClick(e: React.MouseEvent): void {
  e.stopPropagation();
}

export const FdrCommonFormLayout: React.FC<IFdrCommonFormLayoutProps> = ({
  travelDestination,
  travelDates,
  travelRooms,
  travelTypes,
  handleCancel,
  isSubmitAllowed = true,
  sticky,
}) => {
  const [t] = useTranslation('common');

  return (
    <SearchControlInternalContainer className="form-container" sticky={sticky}>
      {travelTypes && (
        <div className="travelTypes search-control-field-travel-types">
          {travelTypes}
        </div>
      )}
      {travelDestination && (
        <div className="travelDestination search-control-field">
          {travelDestination}
        </div>
      )}
      {travelDates && (
        <div className="travelDates search-control-field">{travelDates}</div>
      )}
      {travelRooms && (
        <div className="travelRooms search-control-field travel-rooms-field">
          {travelRooms}
        </div>
      )}
      <SubmitButton
        type="submit"
        data-id="searchButton"
        className="search-button"
        disabled={!isSubmitAllowed}
        onClick={stopPropagationClick}
      >
        {t('common:search')}
      </SubmitButton>
      {handleCancel && (
        <CancelButton
          className="cancel"
          variant={E_BUTTON_TYPE.LINK_BUTTON}
          onClick={handleCancel}
        >
          {t('common:cancel')}
        </CancelButton>
      )}
    </SearchControlInternalContainer>
  );
};
