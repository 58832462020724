import type { DocumentNode } from 'graphql';
import React from 'react';
import { IFormApi } from '@hotelplan/components.common.forms';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { fdrMapSRLSearchControlValuesToFormState } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-response-to-state.mapper';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { FdrFlightHomeSearchControlsDocument } from 'fdr/schemas/query/flight/fdr-flight-home-search-controls.generated';
import { FdrGeoSearchControlsDocument } from 'fdr/schemas/query/geo/search-controls/fdr-geo-search-controls.generated';
import { FdrHomeSearchControlsDocument } from 'fdr/schemas/query/home/search-controls/fdr-home-search-controls.generated';
import { FdrPdpSearchControlsDocument } from 'fdr/schemas/query/pdp/search-controls/fdr-pdp-search-controls.generated';
import { FdrSearchControlsDocument } from 'fdr/schemas/query/search/search-controls/fdr-search-controls.generated';
import { FdrThemeOverviewSearchControlsDocument } from 'fdr/schemas/query/theme-overview/search-controls/fdr-theme-overview-search-controls.generated';
import { FdrThemeSearchControlsDocument } from 'fdr/schemas/query/theme/search-controls/fdr-theme-search-controls.generated';
import { EControlKey } from './fdr-search-control.types';
import useFdrSearchControlForm from './use-fdr-search-control-form';

const searchTypeCfg: Partial<
  Record<ESearchType, { query: DocumentNode; resultKey: EControlKey }>
> = {
  [ESearchType.HOME]: {
    query: FdrHomeSearchControlsDocument,
    resultKey: EControlKey.HOME,
  },
  [ESearchType.SRL]: {
    query: FdrSearchControlsDocument,
    resultKey: EControlKey.SRL,
  },
  [ESearchType.THEME]: {
    query: FdrThemeSearchControlsDocument,
    resultKey: EControlKey.THEME,
  },
  [ESearchType.THEME_OVERVIEW]: {
    query: FdrThemeOverviewSearchControlsDocument,
    resultKey: EControlKey.THEME_OVERVIEW,
  },
  [ESearchType.GEO]: {
    query: FdrGeoSearchControlsDocument,
    resultKey: EControlKey.GEO,
  },
  [ESearchType.FLIGHT]: {
    query: FdrFlightHomeSearchControlsDocument,
    resultKey: EControlKey.FLIGHT_HOME,
  },
  [ESearchType.ORL]: {
    query: FdrPdpSearchControlsDocument,
    resultKey: EControlKey.PDP,
  },
  [ESearchType.PDP]: {
    query: FdrPdpSearchControlsDocument,
    resultKey: EControlKey.PDP,
  },
};

export function useFdrSearchControlFormAction(
  searchType: ESearchType,
  vars: any = {},
  id?: string,
  defaultFormApiRef?: React.MutableRefObject<IFormApi<IFdrSrlControlState>>
) {
  const { query, resultKey } = searchTypeCfg[searchType] || {};

  return useFdrSearchControlForm(
    {
      searchType,
      query,
      variablesMapper: travelType => ({
        travelType: travelType?.fdr,
        ...vars,
        id,
      }),
      dataMapper: data =>
        fdrMapSRLSearchControlValuesToFormState(
          data[resultKey]?.searchControls?.controls
        ),
    },
    id,
    defaultFormApiRef
  );
}
