import { useTransfer } from '@hotelplan/fdr.lib.router.push';
import { useGSSContext } from '@hotelplan/fdr.lib.search.with-global-state';
import { IFdrProductSrlControlState } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { fdrFlightSrlFormToCriteriaInput } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.mappers';
import {
  IFdrFlightSrlControlState,
  TFdrFlightSrlControlStateWithType,
} from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import { useFdrFlightSearchTimestamps } from 'fdr/components/local/fdr-flight-search/use-fdr-flight-srl-timestamps';
import { useFdrFlightSearchLinkLazyQuery } from 'fdr/schemas/query/flight-search/fdr-flight-search-link.generated';

export function useFdrOnFlightSearchApply(
  initialFormValues?: IFdrSrlControlState | null
) {
  const { transfer } = useTransfer();

  const { setFGSS } =
    useGSSContext<IFdrProductSrlControlState, IFdrFlightSrlControlState>();
  const { setFlightSearchRequestTimestamp } = useFdrFlightSearchTimestamps();

  const [getFlightHomeToFlightSrlUrl] = useFdrFlightSearchLinkLazyQuery({
    onCompleted(res) {
      const flightSrlLink = res.fdrFlightSearchResultListPage.webMeta.link;

      if (flightSrlLink?.url) {
        transfer(flightSrlLink);
      }
    },
  });

  return (formState: TFdrFlightSrlControlStateWithType) => {
    const searchCriteria = fdrFlightSrlFormToCriteriaInput(formState);

    setFGSS(initialFormValues || {}, formState);
    setFlightSearchRequestTimestamp();
    getFlightHomeToFlightSrlUrl({
      variables: { input: { searchCriteria } },
    });
  };
}
