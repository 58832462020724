import { getNextPage } from '@hotelplan/components.common.autocomplete';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import {
  trackTravelDestinations,
  TravelDestinationsEventType,
} from '@hotelplan/libs.tracking';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { mapFlatDestinationToTrackableDestination } from 'fdr/components/candidate/fdr-search/mappers/fdr-search-control.mappers';
import { flattenFdrAutocomplete } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-response-to-state.mapper';
import { IFdrDestinationData } from './fdr-destination-field.types';

function mapFdrPaginationToNextPageArgument(fdrPage: FdrPagination) {
  const { page: pageNumber, ...rest } = fdrPage || {};
  return {
    pageNumber,
    ...rest,
  };
}

export const trackDestinationsSearchResult = (
  data: IFdrDestinationData | undefined,
  text: string
) => {
  const destinations = data?.destinations?.map(flattenFdrAutocomplete);
  const currentPage = data?.page?.page || 0;
  const nextPage = getNextPage<TFdrFlatDestination>(
    destinations,
    mapFdrPaginationToNextPageArgument(data?.page)
  );
  const first = currentPage === 0;
  const hasMore = nextPage !== 0;

  trackTravelDestinations({
    type: TravelDestinationsEventType.TRAVEL_DESTINATIONS_RESULTS,
    payload: {
      suggestions: destinations.map(mapFlatDestinationToTrackableDestination),
      first,
      hasMore,
      text,
    },
  });
};
