import React from 'react';
import { useField, useFormContext } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { trackTravelType } from '@hotelplan/libs.tracking';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import {
  T_Custom_TabsTravelTypesUnit,
  U_Extended_TravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import NoSsr from 'fdr/components/local/no-ssr/no-ssr';
import { FdrTravelTypeControls } from './fdr-travel-type-controls';
import { FdrTravelTypeDesktopContent } from './fdr-travel-type-desktop-content';
import {
  fdrMapTravelTypeToTrackableTravelType,
  fdrMapTravelTypeToTravelTypeData,
} from './fdr-travel-type.mappers';
import { isGeneralTravelType } from './fdr-travel-type.utils';

export interface ITravelTypeFieldProps {
  mainTravelTypes: T_Custom_TabsTravelTypesUnit[];
  dropDownTravelTypes?: T_Custom_TabsTravelTypesUnit[];
  withAutoSubmitOnChange?: boolean;
}

const desktopTravelTypeContent = {
  [FdrProductTravelType.Package]: FdrTravelTypeDesktopContent,
};

export const FdrTravelTypeField: React.FC<ITravelTypeFieldProps> = ({
  mainTravelTypes,
  dropDownTravelTypes = [],
  withAutoSubmitOnChange = false,
}) => {
  const [activeType, setActiveType] =
    useField<U_Extended_TravelType | null>(`extended_travelType`);

  const { values, onSubmit } = useFormContext<IFdrSrlControlState>();
  const { mobile } = useDeviceType();

  return (
    <NoSsr>
      <FdrTravelTypeControls
        mainTravelTypes={mainTravelTypes}
        dropDownTravelTypes={dropDownTravelTypes}
        setActiveType={setActiveType}
        activeType={activeType}
        travelTypeContent={mobile ? null : desktopTravelTypeContent}
        onSelectTravelType={travelType => {
          trackTravelType(fdrMapTravelTypeToTrackableTravelType(travelType));

          if (withAutoSubmitOnChange && isGeneralTravelType(travelType)) {
            const travelTypeData = fdrMapTravelTypeToTravelTypeData(travelType);

            onSubmit({
              ...values,
              extended_travelType: travelTypeData.extended_type,
            });
          }
        }}
      />
    </NoSsr>
  );
};
