import React, { FC } from 'react';
import { FdrAutocompleteCountSource } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-count-source';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { IFdrProductSrlControlState } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { allowedTravelDestinationItemTypes } from './fdr-destination-field.constants';
import FdrTravelDestinationTriggerInputCaption from './fdr-destination-trigger-input-caption';

const FdrTravelDestinationFieldCaption: FC<{
  searchControlState?: IFdrProductSrlControlState;
  objectId?: string;
}> = ({ searchControlState, objectId }) => {
  return (
    <>
      <FdrAutocompleteCountSource<TFdrFlatDestination>
        autocompleteItemFilter={(items: TFdrFlatDestination[]) => {
          return items.filter(
            item =>
              allowedTravelDestinationItemTypes.includes(item.__typename) &&
              item.id
          );
        }}
        travelDestinations={
          searchControlState?.legacy_travelDestination?.destinations
        }
        objectId={objectId}
      />
      <FdrTravelDestinationTriggerInputCaption />
    </>
  );
};

export default React.memo(FdrTravelDestinationFieldCaption);
