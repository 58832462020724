import {
  T_Custom_TabsTravelTypesUnit,
  U_Extended_TravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';

export enum EControlKey {
  HOME = 'fdrHomePage',
  SRL = 'fdrProductSearchResultListPage',
  FLIGHT_HOME = 'fdrFlightHomePage',
  THEME = 'fdrTheme',
  THEME_OVERVIEW = 'fdrThemeOverviewPage',
  GEO = 'fdrGeo',
  PDP = 'fdrProduct',
}

export type FdrTravelTypeData = {
  [key in T_Custom_TabsTravelTypesUnit]: {
    extended_type: U_Extended_TravelType | null;
    label: string;
    icon: string;
    link?: string | { [lang: string]: string };
    isExternal?: boolean;
    testId?: string;
  };
};
