import { useSearchState } from '@hotelplan/fdr.lib.search.with-state';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import {
  fdrMapResponseSearchControlsToState,
  fdrSetTravelPeriodInData,
} from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-response-to-state.mapper';
import { IFdrSRLState } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { getCriteria } from 'fdr/components/local/fdr-search-form/fdr-search-form.utils';
import { useFdrControlsTravelPeriodLazyQuery } from 'fdr/schemas/query/search/search-controls/fdr-controls-travel-period.generated';
import { useFdrSearchControlsLazyQuery } from 'fdr/schemas/query/search/search-controls/fdr-search-controls.generated';
import { useFdrSearchLinkLazyQuery } from 'fdr/schemas/query/search/search-link/fdr-search-link.generated';

const SRL_PATH_REG_EXP = /\/(ferien|vacances)\/(suchen|chercher)\?s=/;

export function useSrlFiltersResetOnTravelTypeChange() {
  const { state, setState } = useSearchState<IFdrSRLState>();

  const [requestLink] = useFdrSearchLinkLazyQuery();
  const [requestControls] = useFdrSearchControlsLazyQuery();
  const [requestDefaultPeriod] = useFdrControlsTravelPeriodLazyQuery();

  return async (formState: IFdrSrlControlState) => {
    const searchCriteria = getCriteria(state, formState);

    requestLink({ variables: { input: { searchCriteria } } }).then(
      ({ data }) => {
        const searchQuery =
          data?.fdrProductSearchResultListPage?.webMeta?.link?.url.replace(
            SRL_PATH_REG_EXP,
            ''
          );

        const variables = {
          travelType: formState.extended_travelType.fdr as FdrProductTravelType,
        };

        if (searchQuery) variables['searchQuery'] = searchQuery;

        Promise.all([
          requestControls({ variables }),
          requestDefaultPeriod({ variables: { searchQuery: '' } }),
        ]).then(([{ data: controlsData }, { data: periodData }]) => {
          const withDefaultPeriod = fdrSetTravelPeriodInData(
            controlsData,
            periodData
          );

          const searchControls = fdrMapResponseSearchControlsToState(
            controlsData,
            withDefaultPeriod
          );

          setTimeout(() => {
            setState(searchControls);
          });
        });
      }
    );
  };
}
