import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { ENTER_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import {
  T_Custom_TabsTravelTypesUnit,
  T_Custom_FdrTravelTypeUnit,
  U_Extended_TravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { FdrTravelTypeControlContainer } from './fdr-travel-type-control-container';
import { TravelTypeContentRenderer } from './fdr-travel-type-controls.types';
import { FdrTravelTypeLink } from './fdr-travel-type-link';
import { FdrTravelTypeRadioButton } from './fdr-travel-type-radio-button';
import { fdrMapTravelTypeToTravelTypeData } from './fdr-travel-type.mappers';
import { getLangSpecificLink } from './fdr-travel-type.utils';

const TravelTypeList = styled.ul(
  sx2CssThemeFn({
    display: 'flex',
    boxShadow: 'inset 0 -1px 0 0 #ECECED',
  })
);

const TravelTypeItem = styled.li(({ theme: { colors } }) =>
  sx2CssThemeFn({
    margin: 0,
    boxSizing: 'border-box',
    flex: ['1 0 auto', '1 1 auto'],
    lineHeight: '1',
    '&:focus-visible': {
      '.tabControls': {
        outline: `auto 2px`,
        outlineOffset: '-1px',
        outlineColor: colors.defaultText,
      },
    },
    '&:hover': {
      '.tabControls': {
        color: colors.interactionPrimary,
      },
      '.checked': {
        color: colors.primary,
      },
    },
  })
);

interface ITravelTypeListProps {
  mainTravelTypes: T_Custom_TabsTravelTypesUnit[];
  dropDownTravelTypes?: T_Custom_TabsTravelTypesUnit[];
  activeType?: U_Extended_TravelType | null;
  travelTypeContent?:
    | { [key in T_Custom_FdrTravelTypeUnit]?: TravelTypeContentRenderer }
    | null;
  setActiveType(nextValue: U_Extended_TravelType | null): void;
  onSelectTravelType?(travelType: T_Custom_TabsTravelTypesUnit): void;
}

export const FdrTravelTypeControls: React.FC<ITravelTypeListProps> = ({
  mainTravelTypes,
  dropDownTravelTypes = [],
  activeType,
  setActiveType,
  travelTypeContent,
  onSelectTravelType,
}) => {
  const { t, i18n } = useTranslation('common');
  const { channelType } = useAuthentication();

  return (
    <TravelTypeList role="tablist" className="travelTypeList">
      {mainTravelTypes.map(travelType => {
        const travelTypeData = fdrMapTravelTypeToTravelTypeData(
          travelType,
          channelType
        );

        const travelTypeRenderer = travelTypeContent
          ? travelTypeContent[travelType]
          : null;

        return (
          <TravelTypeItem
            key={travelType}
            className="travelTypeItem"
            role="presentation"
            tabIndex={!travelTypeData.link ? 0 : -1}
            onKeyDown={e => {
              if (e.key === ENTER_KEY && !travelTypeData.link) {
                setActiveType(travelTypeData.extended_type);
                onSelectTravelType?.(travelType);
              }
            }}
          >
            {travelTypeData.link ? (
              <FdrTravelTypeLink
                link={getLangSpecificLink(i18n.language, travelTypeData.link)}
                isExternal={travelTypeData.isExternal}
                icon={travelTypeData.icon}
                label={t(travelTypeData.label)}
                testId={travelTypeData.testId}
                onClick={() => onSelectTravelType?.(travelType)}
              />
            ) : (
              <FdrTravelTypeRadioButton
                travelType={travelType}
                label={t(travelTypeData.label)}
                checked={activeType.fdr === travelType}
                onChange={value => {
                  // @tocheck possible could be used travel data from upper scope
                  const changedTravelTypeData =
                    fdrMapTravelTypeToTravelTypeData(value, channelType);

                  setActiveType(changedTravelTypeData.extended_type);
                  onSelectTravelType?.(value);
                }}
                icon={travelTypeData.icon}
                testId={travelTypeData.testId}
                renderTravelTypeContent={travelTypeRenderer}
              />
            )}
          </TravelTypeItem>
        );
      })}
      {dropDownTravelTypes.length !== 0 && (
        <FdrTravelTypeControlContainer
          dropDownTravelTypes={dropDownTravelTypes}
          onSelectTravelType={onSelectTravelType}
        />
      )}
    </TravelTypeList>
  );
};
