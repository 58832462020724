import { useApolloClient } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { IFormApi } from '@hotelplan/components.common.forms';
import { useGSSContext } from '@hotelplan/fdr.lib.search.with-global-state';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import useFdrSearchControlApply from 'fdr/components/candidate/fdr-search-control/use-fdr-search-control-apply';
import useFdrSearchControlValues, {
  SearchControlDataMapper,
} from 'fdr/components/candidate/fdr-search-control/use-fdr-search-control-values';
import { mergeSearchControlState } from 'fdr/components/candidate/fdr-search/mappers/fdr-search-control.mappers';
import { IFdrProductSrlControlState } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import {
  EFdrFlightTravelType,
  U_Extended_TravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { IFdrFlightSrlControlState } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import { selectDuration } from 'fdr/components/local/fdr-search-travel-dates/fdr-search-travel-dates.utils';
import { ESearchType } from './destination/fdr-destination-field.types';
import { useFdrFlightSearchControlValues } from './use-fdr-flight-search-control-values';

interface IGetValuesQueryConfig<
  TGetValuesQuery extends {},
  TGetValuesVariables
> {
  query: DocumentNode;
  variablesMapper: (travelType?: U_Extended_TravelType) => TGetValuesVariables;
  dataMapper: SearchControlDataMapper<TGetValuesQuery>;
  searchType: ESearchType;
}

export default function useFdrSearchControlForm<
  TGetValuesQuery extends {},
  TGetValuesVariables
>(
  getValuesQueryConfig: IGetValuesQueryConfig<
    TGetValuesQuery,
    TGetValuesVariables
  >,
  objectId?: string,
  defaultFormApiRef?: React.MutableRefObject<IFormApi<IFdrSrlControlState>>
) {
  const prevObjectIdRef = useRef<string | undefined>(objectId);
  const isObjectIdChangedRef = useRef(false);

  const isObjectIdChanged = isObjectIdChangedRef.current;
  isObjectIdChangedRef.current = false;

  const client = useApolloClient();
  const { prefetch } = useRouter();

  // NOTE: Keep first time loaded travel type to see if there are changes to save in GSS.
  const initialTravelTypeRef =
    useRef<U_Extended_TravelType | undefined>(undefined);
  const defaultTravelTypeRef =
    useRef<U_Extended_TravelType | undefined>(undefined);

  const formChangedValuesMap = useRef<{
    [type: string]: IFdrSrlControlState | null;
  }>({
    HOTEL: null,
    FLIGHT: null,
  });

  if (isObjectIdChanged) {
    formChangedValuesMap.current = { HOTEL: null, FLIGHT: null };
  }

  const { gss, fgss, resetGSS } =
    useGSSContext<IFdrProductSrlControlState, IFdrFlightSrlControlState>();

  const localFormApiRef = useRef<IFormApi<IFdrSrlControlState>>(null);
  const formApiRef = defaultFormApiRef || localFormApiRef;

  const [travelType, setTravelType] = useState<
    U_Extended_TravelType | undefined
  >(() =>
    getValuesQueryConfig.searchType === ESearchType.FLIGHT
      ? { fdr: EFdrFlightTravelType.Flight }
      : undefined
  );

  const { data: productOriginalInitialValues, loading: productDataLoading } =
    useFdrSearchControlValues<TGetValuesQuery, TGetValuesVariables>(
      getValuesQueryConfig.query,
      getValuesQueryConfig.variablesMapper(travelType),
      getValuesQueryConfig.dataMapper,
      travelType?.fdr === EFdrFlightTravelType.Flight,
      data => {
        const mappedData = getValuesQueryConfig.dataMapper(data);

        // Updating cache for default travelType
        if (!travelType && mappedData?.extended_travelType) {
          try {
            client.writeQuery<TGetValuesQuery, TGetValuesVariables>({
              query: getValuesQueryConfig.query,
              variables: getValuesQueryConfig.variablesMapper(travelType),
              data,
            });
          } catch (e) {
            // ignore writeCache errors
          }
        }

        // Detect objectId changing to process hard-reset of data
        if (prevObjectIdRef.current !== objectId) {
          isObjectIdChangedRef.current = true;
          defaultTravelTypeRef.current = undefined;
        }

        // NOTE: Update initial travel type with first time loaded travel type value.
        if (!initialTravelTypeRef.current || isObjectIdChangedRef.current) {
          initialTravelTypeRef.current = mappedData?.extended_travelType;
        }

        // Update prev ObjectId after data is loaded
        prevObjectIdRef.current = objectId;
      }
    );

  const { data: flightOriginalInitialValues, loading: flightDataLoading } =
    useFdrFlightSearchControlValues(
      getValuesQueryConfig.searchType,
      travelType?.fdr !== EFdrFlightTravelType.Flight,
      objectId ? { id: objectId } : {},
      extended_travelType => {
        // Detect objectId changing to process hard-reset of data
        if (prevObjectIdRef.current !== objectId) {
          isObjectIdChangedRef.current = true;
          defaultTravelTypeRef.current = undefined;
        }

        // NOTE: Update initial travel type with first time loaded travel type value.
        if (!initialTravelTypeRef.current || isObjectIdChangedRef.current) {
          initialTravelTypeRef.current = extended_travelType;
        }

        // Update prev ObjectId after data is loaded
        prevObjectIdRef.current = objectId;
      }
    );

  const originalInitialValues = useMemo(() => {
    return travelType?.fdr === EFdrFlightTravelType.Flight
      ? flightOriginalInitialValues
      : productOriginalInitialValues;
  }, [productOriginalInitialValues, flightOriginalInitialValues, travelType]);

  if (
    !defaultTravelTypeRef.current &&
    originalInitialValues?.extended_travelType
  ) {
    defaultTravelTypeRef.current = originalInitialValues.extended_travelType;
  }

  const initialValues = useMemo(
    () =>
      getValuesQueryConfig.variablesMapper(travelType)['searchQuery'] // do not merge gss if search query exists
        ? originalInitialValues
        : mergeSearchControlState(originalInitialValues, gss, fgss),
    [originalInitialValues, gss, fgss, travelType, getValuesQueryConfig]
  );

  useEffect(
    function prefetchSearchPages() {
      if (!initialValues) return;

      if (initialValues.type === 'HOTEL') {
        prefetch('/search');
      } else if (initialValues.type === 'FLIGHT') {
        prefetch('/flight-search');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues?.type]
  );

  useEffect(
    function objectIdChangeEffect() {
      if (isObjectIdChanged && initialValues) {
        formApiRef.current?.setValues(initialValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isObjectIdChanged]
  );

  useEffect(
    function formTypeChangeEffect() {
      if (initialValues) {
        if (
          initialValues.extended_travelType &&
          initialValues.extended_travelType?.fdr !== travelType?.fdr
        ) {
          setTravelType(initialValues.extended_travelType);
        }

        if (travelType) {
          formApiRef.current?.setValues({
            ...initialValues,
            ...formChangedValuesMap.current[initialValues.type],
            extended_travelType: travelType,
          });
          if (
            initialValues.type === 'HOTEL' &&
            initialValues?.travelDates?.duration
          ) {
            const duration =
              // @ts-ignore
              formChangedValuesMap?.current?.[initialValues.type]?.travelDates
                ?.duration || initialValues.travelDates.duration;
            selectDuration(duration);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues?.type]
  );

  useEffect(
    function homeFormTypeChangeEffect() {
      if (!initialValues) return;

      if (
        !gss?.travelDates &&
        !gss?.travelRooms &&
        getValuesQueryConfig.searchType === ESearchType.HOME &&
        (travelType?.fdr === FdrProductTravelType.Package ||
          travelType?.fdr === FdrProductTravelType.HotelOnly)
      ) {
        formApiRef.current?.setValues({
          ...initialValues,
          extended_travelType: travelType,
        });
        if (
          initialValues.type === 'HOTEL' &&
          initialValues?.travelDates?.duration
        ) {
          selectDuration(initialValues.travelDates.duration);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [travelType, getValuesQueryConfig.searchType, initialValues, gss]
  );

  const onChange = useCallback((nextFormState: IFdrSrlControlState) => {
    formChangedValuesMap.current[nextFormState.type] = nextFormState;
    setTravelType(nextFormState.extended_travelType);
  }, []);

  const onReset = useCallback(() => {
    const resetValues = {
      ...originalInitialValues,
      travelType: defaultTravelTypeRef.current,
    };
    resetGSS();
    formApiRef.current?.setValues(resetValues);
    onChange(resetValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalInitialValues]);

  const applySearch = useFdrSearchControlApply({
    ...originalInitialValues,
    extended_travelType: initialTravelTypeRef.current,
  });

  // ObjectId changes should reset form, e.g. Geo breadcrumbs transition
  const defaultLoading =
    (!travelType || prevObjectIdRef.current !== objectId) &&
    (productDataLoading || flightDataLoading);

  return {
    initialValues,
    loading: productDataLoading || flightDataLoading,
    defaultLoading,
    travelType,
    formApiRef,
    onChange,
    onReset,
    onApply: applySearch,
  };
}
