import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { IFdrTabsSkeletonProps } from './fdr-search-control-skeleton.types';

export const FdrSearchControlTabsSkeleton: React.FC<IFdrTabsSkeletonProps> = ({
  height = 82,
  maxTabs = 5,
}) => {
  const { mobile } = useDeviceType();

  return (
    <>
      {!mobile && (
        <PlaceholderLoader
          uid="tabsSkeletonSkeleton"
          height={height - 2}
          width={1170}
          style={{ width: '100%', height, display: 'block' }}
        >
          {[
            <React.Fragment key={0}>
              <rect x="38" y="24" width="158" height="25" />
              <rect x="232" width="1" height="80" />
            </React.Fragment>,
            <React.Fragment key={1}>
              <rect x="271" y="24" width="158" height="25" />
              <rect x="467" width="1" height="80" />
            </React.Fragment>,
            <React.Fragment key={2}>
              <rect x="506" y="24" width="158" height="25" />
              <rect x="700" width="1" height="80" />
            </React.Fragment>,
            <React.Fragment key={3}>
              <rect x="739" y="24" width="158" height="25" />
              <rect x="934" width="1" height="80" />
            </React.Fragment>,
            <rect key={4} x="974" y="24" width="158" height="25" />,
          ].slice(0, maxTabs)}
          <rect y="79" width="1170" height="1" />
        </PlaceholderLoader>
      )}
    </>
  );
};
