import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrSearchControlInputsMobileSkeleton } from './fdr-search-control-inputs-mobile-skeleton';
import { SkeletonWhiteWrap } from './fdr-search-control-skeleton.styles';
import { FdrSearchInputsSkeleton } from './fdr-search-inputs-skeleton';

export const FdrSearchControlInputsSkeleton: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { mobile } = useDeviceType();

  return (
    <>
      {mobile && <FdrSearchControlInputsMobileSkeleton />}
      {!mobile && (
        <SkeletonWhiteWrap className={className}>
          <FdrSearchInputsSkeleton />
        </SkeletonWhiteWrap>
      )}
    </>
  );
};
