import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import {
  EFdrFlightTravelType,
  T_Custom_TabsTravelTypesUnit,
  TFdrAdditionTabTravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';

export const mainTravelTypes: T_Custom_TabsTravelTypesUnit[] = [
  FdrProductTravelType.Package,
  EFdrFlightTravelType.Flight,
  FdrProductTravelType.HotelOnly,
  TFdrAdditionTabTravelType.Cruise,
];

export const additionalTravelTypes: T_Custom_TabsTravelTypesUnit[] = [
  TFdrAdditionTabTravelType.Roundtrip,
  TFdrAdditionTabTravelType.Apartment,
];

export const productTravelTypes: T_Custom_TabsTravelTypesUnit[] = [
  FdrProductTravelType.Package,
  FdrProductTravelType.HotelOnly,
];
