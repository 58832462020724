import { useState } from 'react';
import { useTransfer } from '@hotelplan/fdr.lib.router.push';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { FdrLinkType } from '@hotelplan/supergraph-api';

export function useFdrNotOrlModal() {
  const { transfer } = useTransfer();
  const [isModalVisible, showModal, closeModal] = useToggleState(false);
  const [link, setLink] = useState<string | undefined>();
  const [product, setProduct] = useState<string | undefined>();

  return {
    isModalVisible,
    onRenewSearch: () => {
      if (link) {
        transfer({ url: link, type: FdrLinkType.Internal }, false, false);
      }

      setLink(undefined);
      setProduct(undefined);
      closeModal();
    },
    onClose: () => {
      setLink(undefined);
      setProduct(undefined);
      closeModal();
    },
    setLink,
    showModal,
    product,
    setProduct,
  };
}
