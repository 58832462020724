import styled from 'styled-components';

export const FdrTravelDestinationFieldContent = styled.span(
  ({ theme: { colors } }) => ({
    '.trigger-input': {
      input: {
        border: 'none',
        marginTop: '-1px',
        marginBottom: '-1px',
      },
      '.base-input-wrapper': {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: colors.borderColor,
        overflow: 'hidden',
      },
    },
  })
);
