import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrFlightSearchControlsLiteFragmentDoc } from 'fdr/schemas/query/flight-search/form/fdr-flight-search-controls.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHomeFlightSearchControlsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrHomeFlightSearchControlsQuery = {
  __typename?: 'Query';
  fdrHomePage?: {
    __typename?: 'FdrHomePage';
    flightSearchControls: {
      __typename?: 'FdrFlightSearchControlsResponse';
      controls:
        | {
            __typename?: 'FdrOneWayFlightSearchControls';
            sorting: Types.FdrFlightSorting;
            flightClass: Types.FdrFlightClass;
            sortingDirection?: Types.FdrSortDirection | null;
            flight: {
              __typename?: 'FdrSearchControlFlight';
              departureDate: any;
              returnDate: any;
              arrivalAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
              departureAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
            };
            travellers: {
              __typename?: 'FdrFlightSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
            };
          }
        | {
            __typename?: 'FdrOpenJawFlightSearchControls';
            sorting: Types.FdrFlightSorting;
            flightClass: Types.FdrFlightClass;
            sortingDirection?: Types.FdrSortDirection | null;
            flights: Array<{
              __typename?: 'FdrSearchControlFlight';
              departureDate: any;
              returnDate: any;
              arrivalAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
              departureAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
            }>;
            travellers: {
              __typename?: 'FdrFlightSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
            };
          }
        | {
            __typename?: 'FdrReturnFlightSearchControls';
            sorting: Types.FdrFlightSorting;
            flightClass: Types.FdrFlightClass;
            sortingDirection?: Types.FdrSortDirection | null;
            flight: {
              __typename?: 'FdrSearchControlFlight';
              departureDate: any;
              returnDate: any;
              arrivalAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
              departureAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
            };
            travellers: {
              __typename?: 'FdrFlightSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
            };
          };
    };
  } | null;
};

export const FdrHomeFlightSearchControlsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHomeFlightSearchControls' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHomePage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flightSearchControls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'controls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrFlightSearchControlsLite',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFlightSearchControlsLiteFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHomeFlightSearchControlsQuery__
 *
 * To run a query within a React component, call `useFdrHomeFlightSearchControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHomeFlightSearchControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHomeFlightSearchControlsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrHomeFlightSearchControlsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrHomeFlightSearchControlsQuery,
    FdrHomeFlightSearchControlsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHomeFlightSearchControlsQuery,
    FdrHomeFlightSearchControlsQueryVariables
  >(FdrHomeFlightSearchControlsDocument, options);
}
export function useFdrHomeFlightSearchControlsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHomeFlightSearchControlsQuery,
    FdrHomeFlightSearchControlsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHomeFlightSearchControlsQuery,
    FdrHomeFlightSearchControlsQueryVariables
  >(FdrHomeFlightSearchControlsDocument, options);
}
export type FdrHomeFlightSearchControlsQueryHookResult = ReturnType<
  typeof useFdrHomeFlightSearchControlsQuery
>;
export type FdrHomeFlightSearchControlsLazyQueryHookResult = ReturnType<
  typeof useFdrHomeFlightSearchControlsLazyQuery
>;
export type FdrHomeFlightSearchControlsQueryResult = Apollo.QueryResult<
  FdrHomeFlightSearchControlsQuery,
  FdrHomeFlightSearchControlsQueryVariables
>;
