import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useField, useFormContext } from '@hotelplan/components.common.forms';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { trackTravelType } from '@hotelplan/libs.tracking';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { U_Extended_TravelType } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { FdrTravelTypeDesktopContent } from './fdr-travel-type-desktop-content';
import { ITravelTypeFieldProps } from './fdr-travel-type-field';
import { FdrTravelTypeLink } from './fdr-travel-type-link';
import {
  fdrMapTravelTypeToTravelTypeData,
  fdrMapTravelTypeToTrackableTravelType,
} from './fdr-travel-type.mappers';
import {
  getLangSpecificLink,
  isGeneralTravelType,
} from './fdr-travel-type.utils';

const FdrDropDownWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'background',
    position: 'absolute',
    top: '100%',
    right: 0,
    zIndex: 1,
    boxShadow: '0px 4px 4px rgba(136, 136, 136, 0.1)',
  })
);

const FdrTravelTypesWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    '.travel-type-base-link': {
      margin: '0',
      '&:hover': {
        color: 'black',
      },
      '> div': {
        margin: '0',
        border: 'none',
      },
    },
  })
);
const FdrTravelTypesInner = styled.div<{ isDropdownOpened: boolean }>(
  ({ isDropdownOpened }) =>
    sx2CssThemeFn({
      boxShadow: isDropdownOpened && 'inset 0 -3px 0 0 #E30613',
      border: '1px solid',
      borderColor: 'lightGreyFirst',
      '> div': {
        margin: '0',
        border: 'none',
      },
      '.checked': {
        color: 'black',
      },
      '.tabControls': {
        height: '46px',
        transition: 'none',
        borderBottomColor: isDropdownOpened && 'primary',
      },
    })
);

const FdrTravelTypesContainer = ({
  mainTravelTypes,
}: Partial<ITravelTypeFieldProps>) => {
  const [t, i18n] = useTranslation('common');
  const { channelType } = useAuthentication();
  const { values, onSubmit } = useFormContext<IFdrSrlControlState>();
  const [isDropdownOpened, , closeField, toggle] = useToggleState(false);
  const [activeType, setActiveType] =
    useField<U_Extended_TravelType | null>(`extended_travelType`);

  const dropDownRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLDivElement>(null);

  useOnClickOutside<HTMLDivElement>(
    [dropDownRef, tabRef],
    () => {
      closeField();
    },
    isDropdownOpened
  );

  return (
    <FdrTravelTypesWrapper onClick={toggle}>
      {mainTravelTypes.map(travelType => {
        const travelTypeData = fdrMapTravelTypeToTravelTypeData(
          travelType,
          channelType
        );

        return activeType.fdr === travelType ? (
          <FdrTravelTypesInner
            isDropdownOpened={isDropdownOpened}
            ref={tabRef}
            key={travelType}
          >
            {FdrProductTravelType.Package === travelType ? (
              <FdrTravelTypeDesktopContent checked={isDropdownOpened} />
            ) : (
              <FdrTravelTypeLink
                link={getLangSpecificLink(i18n.language, travelTypeData.link)}
                isExternal={travelTypeData.isExternal}
                icon={travelTypeData.icon}
                label={t(travelTypeData.label)}
                testId={travelTypeData.testId}
              />
            )}
          </FdrTravelTypesInner>
        ) : null;
      })}

      {isDropdownOpened ? (
        <FdrDropDownWrapper ref={dropDownRef}>
          {mainTravelTypes.map(travelType => {
            const travelTypeData = fdrMapTravelTypeToTravelTypeData(
              travelType,
              channelType
            );

            return travelTypeData && activeType.fdr !== travelType ? (
              <React.Fragment key={travelType}>
                <FdrTravelTypeLink
                  testId={travelTypeData.testId}
                  link={getLangSpecificLink(i18n.language, travelTypeData.link)}
                  isExternal={travelTypeData.isExternal}
                  icon={travelTypeData.icon}
                  label={t(travelTypeData.label)}
                  onClick={() => {
                    trackTravelType(
                      fdrMapTravelTypeToTrackableTravelType(travelType)
                    );

                    if (isGeneralTravelType(travelType)) {
                      setActiveType(travelTypeData.extended_type);
                      onSubmit({
                        ...values,
                        extended_travelType: travelTypeData.extended_type,
                      });
                    }
                  }}
                >
                  {FdrProductTravelType.Package === travelType ? (
                    <FdrTravelTypeDesktopContent />
                  ) : null}
                </FdrTravelTypeLink>
              </React.Fragment>
            ) : null;
          })}
        </FdrDropDownWrapper>
      ) : null}
    </FdrTravelTypesWrapper>
  );
};

export default FdrTravelTypesContainer;
