import { useTranslation } from 'next-i18next';
import React from 'react';
import { FdrAutocompleteComboboxInput } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-combobox';
import {
  FdrTravelDestinationLabel,
  TFdrFlatDestination,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import {
  trackRemoveSuggestion,
  trackSelectSuggestion,
  trackTravelDestinations,
  TravelDestinationsEventType,
} from '@hotelplan/libs.tracking';
import { getShortInformation } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.util';
import { FdrTravelDestinationSuggestion } from 'fdr/components/candidate/fdr-search-control/destination/suggestion/fdr-travel-destination-suggestion';
import { mapFlatDestinationToTrackableDestination } from 'fdr/components/candidate/fdr-search/mappers/fdr-search-control.mappers';
import { IFdrTravelDestinationInputProps } from './fdr-travel-destination-input.types';
import { getDestinationName } from './fdr-travel-destination-input.utils';

export function defaultRenderSuggestion(
  item: TFdrFlatDestination,
  query: string
): React.ReactNode {
  return <FdrTravelDestinationSuggestion item={item} query={query} />;
}

export function renderDestinationTagLabel(
  item: TFdrFlatDestination
): React.ReactNode {
  return <FdrTravelDestinationLabel item={item} label={item.name} />;
}

export const FdrTravelDestinationInput: React.FC<IFdrTravelDestinationInputProps> =
  props => {
    const {
      label,
      children,
      placeholder,
      value,
      onChange,
      onSelect,
      className,
      config,
      limit,
      caption,
      customLabel,
      renderSuggestion,
    } = props;

    const [t] = useTranslation('search');

    const autocompletePlaceholder = !config?.disableAutocompleteTags
      ? placeholder
      : getShortInformation(t, value || [], limit) || placeholder;

    return (
      <FdrAutocompleteComboboxInput<TFdrFlatDestination>
        travelDestinationConfig={config}
        maxItems={config?.maxItems}
        className={className}
        name="travelDestination"
        value={value || []}
        onChange={onChange}
        icon={{ name: 'planet' }}
        label={label}
        customLabel={customLabel}
        placeholder={autocompletePlaceholder}
        displayValue={getShortInformation(t, value || [], limit)}
        getTagName={getDestinationName}
        renderTagLabel={renderDestinationTagLabel}
        renderSuggestion={renderSuggestion ?? defaultRenderSuggestion}
        caption={caption}
        onOpen={() => {
          trackTravelDestinations({
            type: TravelDestinationsEventType.TRAVEL_DESTINATIONS_OPEN,
          });
        }}
        onCancel={() => {
          trackTravelDestinations({
            type: TravelDestinationsEventType.TRAVEL_DESTINATIONS_CANCEL,
          });
        }}
        onApply={items => {
          trackTravelDestinations({
            type: TravelDestinationsEventType.TRAVEL_DESTINATIONS_APPLY,
            payload: {
              suggestions: items.map(mapFlatDestinationToTrackableDestination),
            },
          });
        }}
        onOptionSelect={
          onSelect
            ? onSelect
            : (item, text) =>
                trackSelectSuggestion(
                  mapFlatDestinationToTrackableDestination(item),
                  text
                )
        }
        onOptionRemove={(item, text) =>
          trackRemoveSuggestion(
            mapFlatDestinationToTrackableDestination(item),
            text
          )
        }
      >
        {children}
      </FdrAutocompleteComboboxInput>
    );
  };
