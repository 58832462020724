import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';

export const FdrSearchControlInputsMobileSkeleton: React.FC = () => (
  <PlaceholderLoader
    uid="currentSearchSkeleton"
    width={375}
    height={258}
    style={{ width: '100%', height: '100%' }}
  >
    <rect x="24" y="21" width="326" height="45" />
    <rect x="24" y="74" width="326" height="45" />
    <rect x="24" y="127" width="326" height="45" />
    <rect x="24" y="188" width="326" height="52" rx="4" />
  </PlaceholderLoader>
);
