import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';

export const FdrSearchControlMobileSkeleton: React.FC = props => (
  <PlaceholderLoader
    uid="currentSearchSkeleton"
    height={328}
    width={375}
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <rect x="24" y="42" width="62" height="14" rx="7" />
    <rect x="133" y="42" width="62" height="14" rx="7" />
    <rect x="244" y="42" width="62" height="14" rx="7" />
    <rect x="338" y="42" width="29" height="14" rx="7" />
    <circle cx="164" cy="27" r="10" />
    <circle cx="275" cy="27" r="10" />
    <circle cx="352" cy="25" r="10" />
    <circle cx="44" cy="27" r="10" />
    <circle cx="67" cy="27" r="10" />
    <rect x="24" y="91" width="326" height="45" />
    <rect x="24" y="144" width="326" height="45" />
    <rect x="24" y="197" width="326" height="45" />
    <rect x="24" y="258" width="326" height="52" rx="4" />
    <rect width="375" height="0.96401" transform="matrix(1 0 0 -1 0 1)" />
    <rect width="375" height="0.96401" transform="matrix(1 0 0 -1 0 70)" />
    <rect width="375" height="0.96401" transform="matrix(1 0 0 -1 1 328)" />
    <rect width="1.01449" height="70" transform="matrix(-1 0 0 1 110 0)" />
    <rect width="1" height="69" transform="matrix(-1 0 0 1 219 0)" />
    <rect width="1" height="69" transform="matrix(-1 0 0 1 329 0)" />
  </PlaceholderLoader>
);
