import React from 'react';
import { TabControls } from '@hotelplan/components.common.tabs';
import { FdrLinkOpenMethod, FdrLinkType } from '@hotelplan/supergraph-api';
import {
  FdrRouteTravelTypeBaseLink,
  FdrTravelTypeBaseLink,
} from './fdr-travel-type-link.styles';

interface ITravelTypeLink extends React.PropsWithChildren<{}> {
  link?: string;
  isExternal?: boolean;
  label: string;
  icon: string;
  testId?: string;
  onClick?: () => void;
}

export const FdrTravelTypeLink: React.FC<ITravelTypeLink> = ({
  link = '',
  isExternal = false,
  label,
  icon,
  testId,
  onClick,
  children,
}) => {
  const commonProps = {
    onClick,
    testId,
    className: 'travel-type-base-link',
  };

  const childrenElement = children ? (
    children
  ) : (
    <TabControls icon={icon}>{label}</TabControls>
  );

  if (!link)
    return (
      <FdrTravelTypeBaseLink {...commonProps}>
        {childrenElement}
      </FdrTravelTypeBaseLink>
    );

  return (
    <FdrRouteTravelTypeBaseLink
      link={{
        url: link,
        type: isExternal ? FdrLinkType.External : FdrLinkType.Internal,
        openMethod: FdrLinkOpenMethod.NewTab,
      }}
      {...commonProps}
    >
      {childrenElement}
    </FdrRouteTravelTypeBaseLink>
  );
};
